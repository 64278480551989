import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { getAuth } from '../../app/modules/auth';
import { clearUserData, getCookie, setCookie } from './utils';
import { isAuth } from '../../app/router/AppRoutes';
export const ApiService = axios.create()

ApiService.defaults.baseURL = process.env.REACT_APP_API_URL;
ApiService.defaults.timeout = 120 * 1000

ApiService.interceptors.request.use(async (config: any) => {
  const auth = getAuth() as any
  let token = getCookie('token')
  // console.log('token',token)
  // console.log('auth',auth)
  if (auth && token) {
    config.headers.Authorization = `bearer ${token}`
    config.headers['Access-Control-Max-Age'] = 86400
  }
  return config;
}, (error) => Promise.reject(error));

// ApiService.interceptors.response.use(
//   response => response,
//   async (error) => {
//     let status = error.request.status
//     let message = JSON.parse(error.request.response).message
//     if (status == 403) {
//       // window.location.href = `/error/403?message=${message}`
//     }
//     return Promise.reject(error);
//   }
// );

let isRefreshing = false;

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let originalRequest: AxiosError['config'] = error.config;
  const status = error.response?.status || 0;
  const statuscode = (error.response?.data as any)?.errorCode || 0;
  const errorMessage = (error.response?.data as any)?.errorMessage || ''
  const codeErr = (error as any).code
  // console.log('sdadadsa error',error)
  if (axios.isCancel(error)) {
      console.log('Request cancel', error.message);
      return Promise.reject(error);
  }
  
  // Kiểm tra mã lỗi
  if (status == 403 && originalRequest && !isRefreshing && statuscode != '403-5000') {
      console.log('statuscode',statuscode)
      if ((statuscode !== '403-284210010' && statuscode !== '403-284210000') || errorMessage.includes('Token Revoked')) {
          clearUserData()
          // console.log('aaaaaaaaaa')
          // window.location.href = '/login'
          return Promise.reject(error);
      }
      isRefreshing = true;
      const token = getCookie('token')
      if (!token) {
          clearUserData()
          return Promise.reject(error);
      }
      try {
          // Gọi API để lấy token mới
          // const refresh = await isAuth()
          console.log('call authen')
          const { isAuthenticated, newAccessToken } = await isAuth()||{}
          
        // if (newAccessToken && isAuthenticated) await saveAuth({ ...auth, token: newAccessToken })
        if (!isAuthenticated) {  
          clearUserData()
         }
          const newToken = newAccessToken
          if (!newToken) {
              return Promise.reject(error);
          }

          setCookie('token', newToken, 365)
          ApiService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          // Call lại request đã bị lỗi
          await ApiService(originalRequest);
      } catch (error) {
          clearUserData()
          return Promise.reject(error);
      } finally {
          isRefreshing = false;
      }
  }
  
  // Nếu không, trả lỗi về điểm cuối đã gọi api
  return Promise.reject(error);
}
const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
}
ApiService.interceptors.response.use(onResponse, onResponseError)
