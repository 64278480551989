/* eslint-disable */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx'
import { Dropdown, Modal } from 'react-bootstrap'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { yupResolver } from "@hookform/resolvers/yup"
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from "yup"
import { ApiService } from '../../../../theme/helpers/ApiService'
import { PageLink, PageTitle } from '../../../../theme/layout/core'
import SearchPopup from '../../../../theme/partials/layout/search-popup'
import { KTFormItem } from '../../../../theme/partials/widgets/utils/KTFormItem'
import { KTSVG } from '../../../../theme/partials/widgets/utils/KTSVG'
import { useAuth } from '../../../modules/auth'
import { ShowForPermission, getIsPermission } from '../../../modules/auth/Permission'
import { popupCustomQuestion, popupDelete, popupLoading, popupMessage } from '../../../modules/messages'
import { CustomHeader, BasicCell, InputDate, InputSelect, InputPrice, InputFiles, KTButton, RowIcon, FormSubmit, TextFormByUser, DivRoot } from '../../../../theme/partials'
import { KTTable } from '../../../../theme/partials/widgets/table'
import { ApiShowError, ApiUploadFiles, downloadFile, exportCSV, formatCurrent, formatOptions, getLocalStorage, getValueId, reFormatCurrent, removeAccents, sortWithAny, sortWithDate, unique_arr } from '../../../../theme/helpers'
import { ContentHeader } from '../../../../theme/layout/components/content'
import { useAdvanceForme, useApprovalHistories, usePaymentId, usePaymentIdByQuery } from '../../../modules/api/requests'
import { LocalData } from '../../../modules/api/localData'
import { apiPrivate } from '../../../modules/api'
import { apiAd } from '../../../modules/api/admin'
import { approvalusersetupsByQuery, convertOperatorObject, convertStatus, convertToIfCondition, customFilter, formatBytes, formatDataLevel, getEmailInfo, getInforGroup, getInforUser, getOptions, historiesApproval, setValueGrid, templateCell } from '../../orderRequest/details/function'
import { ActionApprovals } from '../../orderRequest/details/actions'
import { useIntl } from 'react-intl'
import { ImportModalRequest } from '../../orderRequest/details/importModal'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useZustandStore } from '../../../modules/store'
import { getOptionCustomers, getOptionVenders } from './funcStore'
import { InputSelectCell } from '../../orderRequest/details/InputSelectCell'


// Then import the virtualized Select HOC
// import VirtualizedSelect from 'react-virtualized-select'
// import { dataEmployees, dataVenders } from './funcStore'
const TemplateComponent: FC<{ permission: string }> = ({ permission }) => {
  const permission_modify = getIsPermission([permission,'MODIFY'])
  const permission_insert = getIsPermission([permission,'INSERT'])
  
  const zustandStore = useZustandStore() as any
  const { id } = useParams() as any
  const { isEdit, valueid } = getValueId(id)
  const { data: dataDepartments } = apiPrivate.useDepartments()
  const {auth} = useAuth()
  const {user} = auth || {} as any
  const [idRequest,setidRequest] = useState<any>(valueid)
  const intl = useIntl()
  let {selectedLang} = getLocalStorage('siteConfig') as any
  selectedLang = selectedLang||'vi'

  const navigate = useNavigate();
  // const { data: dataDepartments } = apiPrivate.useDepartments()
  const { data: dataExpenses } = apiPrivate.useExpensesByQuery('?expand=expensevalues')
  // const { data: dataVendors } = apiPrivate.useVendorsByQuery('?expand=vendorbankaccounts')
  // const { data: dataCustomers } = apiPrivate.useCustomersByQuery('?expand=customerbankaccounts')

  const [optionsExpenses,setOptionsExpenses] = useState<any>([])
  const [optionsAdvances,setOptionsAdvances] = useState<any>([])
  const [optionsVenders,setOptionsVenders] = useState<any>([])
  const [optionsEmployees,setOptionsEmployees] = useState<any>([])
  
  const [optionsCustomers,setOptionsCustomers] = useState<any>([])
  const optionsVendersType = Object.values(LocalData.venderType).map((i: any) =>({
    label: i['label'+selectedLang],
    value: i.value
  }))
  const [autoApprovalRequest,setAutoApprovalRequest] = useState<any>(false)
  const [optionsStores,setOptionsStores] = useState<any>([])
  const [optionsStoresName,setOptionsStoresName] = useState<any>([])
  const [optionsUoms, setOptionsUoms] = useState<any>([])
  const [employeeAdvance,setEmployeeAdvance] = useState<any>([])
  
  const [paymentrequestforsomeone,setpaymentrequestforsomeone] = useState<any>(false)
  const { data: dataSelf, isSuccess: isdataSelf } = apiAd.useSelfServiceSetups()
  const [optionsDepts,setOptionsDepts] = useState<any>([])
  const [isEditNotApproval,setIsEditNotApproval] = useState<any>(false)
  const { data: uomsData, isSuccess: isUoms } = apiPrivate.useUoms()
  const optionUoms = isUoms ? uomsData.map((e: any) => ({ label: e.displayname, value: e.id, title: e.displayname, description: e.code, ...e })) as any : []
  useEffect(() => {
    setOptionsEmployees(zustandStore?.dataEmployees||[])
  },[zustandStore?.dataEmployees])
  useEffect(() => {
    setOptionsVenders(zustandStore?.dataVenders||[])
  },[zustandStore?.dataVenders])
  useEffect(() => {
    setOptionsCustomers(zustandStore?.dataCustomers||[])
  },[zustandStore?.dataCustomers])
  useEffect(() => {
    setOptionsExpenses(zustandStore?.dataExpenses||[])
  },[zustandStore?.dataExpenses])
  useEffect(() => {
    if (dataDepartments && dataDepartments.length != 0) {
      setOptionsDepts(formatOptions(dataDepartments, ['id', 'code']))
    }
  }, [dataDepartments])
  useEffect(() => {
    if (dataSelf && dataSelf.length != 0) {
      let data = dataSelf.pop()
      setpaymentrequestforsomeone(data.paymentrequestforsomeone)
      // getDimensionValues(data.globaldimension1code)
    }
}, [dataSelf])
const getDimensionValues = async() => {
  let type = '' as any
  await ApiService.get(`/settings/selfservicesetups`)
  .then(({data}) => {
    let dataSelf = data as any
    if (dataSelf && dataSelf.length != 0) {
      dataSelf = dataSelf.pop()
      setpaymentrequestforsomeone(dataSelf.paymentrequestforsomeone)
      type = dataSelf.globaldimension1code
    }
  })
  .catch(({response}) => {
    Swal.fire({
      icon: 'error',
      title: 'An error occurred',
      showConfirmButton: false,
      timer: 1500
    })
  })
  const {data} = await ApiService.get(`/settings/dimensionvalues/?dimensioncode=${type}`)
  // console.log('daya',data)
  if(data) {
    let tempData = data.map((e: any) => ({ ...e, label: e.code, value: e.code, subTitle: e.name ,description: e.name  }))
    setOptionsStores(
      tempData
    )
    setOptionsStoresName(
      tempData
    )
    return tempData
  }
 }
  // const { data: dataAdvances } = useAdvanceForme(`?createdby=${user?.id}&status=284210002`)//'?expand=employeeadvancerequestlines'
  const getAdvances = async (sourceno: any) => {
    setValue('employeeadvancerequestno',null)
    setValue('employeeadvancerequest',null)
    setValue('remainingadvancedamount',null)
    setValue('advancedamount',null)
    if(!sourceno) {
      setOptionsAdvances([])
      return []
    }
    const {data} = await ApiService.get(`/employees/employeeadvancerequests?sourceno=${sourceno}&status=284210002`,)
    setEmployeeAdvance(data.items)
    setOptionsAdvances(
      formatOptionAdvance(data.items?.filter((i:any)=>i.open != false))
      )
    return data.items
  }
  const formatOptionAdvance = (data:any) => {
    data = data?.map((e: any) => {
      let title = e.documentno + ' | ' + formatCurrent(e.remainingadvancedamount)
      let description = e.description + ', ngày ' +new Date(e.createdat).toLocaleDateString('en-GB')
      let description1 = e.description
      return {...e,value: e.id,label: title, title: title, description: description,description1: description1 }
    })
    return data
  }
  const { data: dataQuery, isSuccess, isFetched, isFetching, refetch } = usePaymentIdByQuery(idRequest,'?expand=paymentrequestlines,User')
  const [ dataRequest, setDataRequest] = useState<any>(dataQuery)

  const { data: dataRequiredDocument } = apiAd.useRequiredDocumentsByQuery(`?objectcode=${permission}`)
  const [requiredDocument,setRequiredDocument] = useState(dataRequiredDocument?.map((i:any)=> {return{values: [],...i}}))
  const { data: dataApprovalHistories,refetch: refetchApproval, isSuccess: isSuccessHistories } = useApprovalHistories(idRequest && `?documentid=${idRequest}&expand=approvaldetails`)||{}
  const { data: dataApprovals, isSuccess: isSuccessApprovals, isFetching: isFetchingApp, refetch: dataSetupApproval } = apiAd.useApprovalsByQuery(
    idRequest ? dataRequest && `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${dataRequest?.createdby})`:
    `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${user?.id})`
    )
  const [approvalLevels,setApprovalLevels] = useState<any>([])
  const [requestUser,setRequestUser] = useState<any>(undefined)
  const [approvalUser,setApprovalUser] = useState<any>(null)
  const [approvalhistories,setApprovalhistories] = useState<any>(null)
  const { data: objectData, isSuccess: isObjectData } = apiAd.useObjectsByNumber(permission)
  const arrObject: any = isObjectData ? objectData[0] : undefined
  const refApprovalRequest = useRef<any>()
  const refSaveBtn = useRef<any>()
  const [isHasChange,setIsHasChange] = useState<any>(false)
  const [emailnotification,setEmailnotification] = useState<any>({})
  const [listFiles,setListFiles] = useState<any>([])
  const [filesDelete,setFilesDelete] = useState<any>([])
  const checkNumberOrder = (value: any) => {
    let regex = /^[0-9;,+_\s-]*$/
    if(value) {
      if(!regex.test(value)) {
        value = ''
      }
    }
    return value
  }
  let style = {
    menu: (provided: any) => ({
      ...provided,
      width: "max-content",
      minWidth: "100%"
    }),
    control: (base: any, state: any) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0
      }
    })} as any
  const formatOptionLabel = (props: any) => {
    const {label, value, subTitle} = props
    return (
      <div className='text-start'>
        <div>{label}</div>
        {/* <div className='d-block text-gray-700'>
          {subTitle}
        </div> */}
      </div>
    )
  };
  const optionDimensionData = useMemo(() => {
    return optionsStoresName;
  }, [optionsStoresName]);
  const optionUomsData = useMemo(() => {
    return optionUoms;
  }, [optionUoms]);
  const handleChange = (e: any,props: any) => {
    const state = {} as any
    const {onCommit, onRevert} = props
    if (!e) {
      return onRevert()
    }
    onCommit(e.value, state.e)
  };

  const SelectEditorDimension = (props: any) => {
    const state = {} as any
    let optionsData = zustandStore?.dataVenders || []
  
    const templateCell = props.cell.templateCell
    const handleChange = (e: any) => {
      const {onCommit, onRevert} = props
      if (!e) {
        return onRevert()
      }
      onCommit(e.value, state.e)
    };
    // console.log('props',props)
    // console.log('optionsData',optionsData)
    
    // console.log('zustandStore?.dataVenders',memoizedValue)
    let options = optionsData?.map((e:any)=>({ props: props, ...e, value: e.label }))
    const handleKeyDown = (e: any) => {
      var TAB_KEY = 9;
      var ENTER_KEY =  13;
      // record last key pressed so we can handle enter
      if (e.which === ENTER_KEY || e.which === TAB_KEY) {
        e.persist()
        state.e = e
      } else {
        state.e = null
      }
    }
    return <InputSelectCell
    isAsync={true}
    options={options}
    value={props.value}
    onChange={handleChange}
    formatOptionLabel={templateCell?templateCell:formatOptionLabel}
    className='custom-select-cell'
    filterOption={customFilter}
  />
     
    
  };
  const SelectEditorPopup = (props: any) => {
    const state = {} as any
    let optionsData = props.cell.optionsData
    const templateCell = props.cell.templateCell
    const createdSelect = props.cell.createdSelect
    const typeCell = props.cell.typeCell
    const index = props.row
    const isAsync = props.cell.isAsync
    const readOnly = props.readOnly
    // console.log('props',props)
    let style = {
      menu: (provided: any) => ({
        ...provided,
        width: "max-content",
        minWidth: "100%"
      }),
      control: (base: any, state: any) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0
        }
      })} as any
      const formatOptionLabel = (props: any) => {
        const {label, value, subTitle} = props
        return (
          <div className='text-start'>
            <div>{label}</div>
            {/* <div className='d-block text-gray-700'>
              {subTitle}
            </div> */}
          </div>
        )
      };

    if(typeCell == 'custom-name') {
      optionsData =  optionsPayments('name').filter((i:any)=>!checkChoise(i.value))
    }
    if(typeCell == 'custom-code') {
      optionsData =  optionsPayments('code').filter((i:any)=>!checkChoise(i.value))
      style.indicatorsContainer = (prevStyle: any, state: any) => (
        {
          ...prevStyle,
          display: 'none'
        }
      )
    }
    const handleChange = (e: any) => {
      // console.log('aaaaaaaaaaaa',e)
      const {onCommit, onRevert} = props
      if (!e) {
        return onRevert()
      }
      onCommit(e.value, state.e)
    };
    let options = optionsData.map((e:any)=>({ props: props, ...e, value: e.label }))
    const handleKeyDown = (e: any) => {
      var TAB_KEY = 9;
      var ENTER_KEY =  13;
      // record last key pressed so we can handle enter
      if (e.which === ENTER_KEY || e.which === TAB_KEY) {
        e.persist()
        state.e = e
      } else {
        state.e = null
      }
    }
   
    
    const memoizedDataTable = useMemo(() => <div>
    {/* <InputSelectCell
    isAsync={isAsync}
    options={options}
    value={props.value}
    onChange={handleChange}
    formatOptionLabel={templateCell?templateCell:formatOptionLabel}
    className='custom-select-cell'
    filterOption={customFilter}


  /> */}
  <input type="text" autoFocus value={props.value} onChange={handleChange} className='custom-select-cell'/>
    </div>, [options]);
  // console.log('isAsync',isAsync)
  const {value, onKeyDown} = props
  let _input = useRef() as any
  // setTimeout(()=>{
  //   _input?.focus()
  // },500)
    return isAsync ? <input
    // ref={input => { _input = input }}
    type='text'
    className='data-editor'
    value={value}
    readOnly={readOnly}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
  /> : createdSelect?
    <CreatableSelect
      autoFocus
      openMenuOnFocus
      closeMenuOnSelect
      value={props.value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      options={options}
      isDisabled={readOnly}
      className='custom-select-cell custom-search-select'
      styles={style}
      placeholder={""}
      formatOptionLabel={templateCell?templateCell:formatOptionLabel}
      filterOption={customFilter}
    />:
    <ReactSelect
    autoFocus
    openMenuOnFocus
    closeMenuOnSelect
    value={props.value}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    isDisabled={readOnly}
    options={options}
    className='custom-select-cell custom-search-select'
    styles={style}
    placeholder={""}
    formatOptionLabel={templateCell?templateCell:formatOptionLabel}
    filterOption={customFilter}
  

  />
     
    
  };

  useEffect(() => {
      if(dataQuery && isSuccess) {
        let data = JSON.parse(JSON.stringify(dataQuery))
        data.documentdate = data.documentdate && [new Date(data.documentdate)]
        if(dataQuery) {
          getData(data)
        }
      }
      
  }, [dataQuery])
  useEffect(() => {
    if(!valueid) {
      getData(undefined)
    }
  }, [id])
  useEffect(() => {
    setApprovalhistories(null)
  }, [])
  
  useEffect(() => {
    if(dataApprovalHistories && dataApprovalHistories.length != 0) {
      setApprovalhistories(dataApprovalHistories[0])
    }
    else {
      setApprovalhistories(null)
    }
  }, [dataApprovalHistories])
  const checkIsDisabledForm:any = () => {
    // permission_insert
    if(!permission_insert && !idRequest) return true
    else if(!permission_modify) return true
    if(isEditNotApproval) return false
    if(!idRequest) return false
    if (!requestUser) return true
    return  (approvalhistories?.currentstatus == '284210001' || approvalhistories?.currentstatus == '284210002' || approvalhistories?.currentstatus == '284210004')
  }
  
  
  useEffect(() => {
    if(dataExpenses) {
      setOptionsExpenses(
        dataExpenses.map((e: any) => ({ label: e.code, value: e.id, title: e.code ,description: e.expensename, ...e }))
        )
    }
  }, [dataExpenses])
  // useEffect(() => {
  //   if(dataStores) {
  //     setOptionsStores(
  //       dataStores.map((e: any) => ({ label: e.storename, value: e.id, title: e.storename ,description: e.code, ...e }))
  //       )
  //       setOptionsStoresCode(
  //         dataStores.map((e: any) => ({ label: e.code, value: e.id, title: e.code ,description: e.storename, ...e }))
  //         )
  //   }
  // }, [dataStores])
  

  
  const [optionsNo,setOptionsNo] = useState<any>([])
  const [optionsName,setOptionsName] = useState<any>([])
  

  let schema = {
    documentdate: yup.array()
      .required('Input is required'),
    documenttype: yup.string()
      .required('Input is required'),
    sourceno: yup.string()
      .required('Input is required'),
    expensecode: yup.string()
      .required('Input is required'),
    paymentmethodcode: yup.string()
      .required('Input is required'),
    expensename: yup.string()
      .required('Input is required'),
    // departmentid: yup.string()
    //   .required('Input is required'),
  }
    if(!(arrObject?.nosericode && !idRequest)) {
      schema = Object.assign(schema,{documentno: yup.string()
        .required('Input is required')})
        
    }
  // const yupSchema = yup.object().shape(schema)
  const [yupSchema,setYupSchema] = useState<any>(yup.object().shape(schema))
  useEffect(()=>{
    if(objectData) {
      if(!(arrObject?.nosericode && !idRequest)) {
        schema = Object.assign(schema,{documentno: yup.string()
          .required('Input is required')})
          setYupSchema(yup.object().shape(schema))
      }
    }
  },[objectData])
  const [yupSchemaFile,setYupSchemaFile] = useState<any>()
  function checkIfFilesAreTooBig(files?: [File],maxSize?:any): boolean {
    let valid = true
    if (files) {
      files.map(file => {
        const size = file.size / 1024 / 1024
        if (size > maxSize) {
          valid = false
        }
      })
    }
    return valid
  }
  const checkFile = (item: any) => {
    let check = false
    let arrFiles = listFiles.map((item:any)=> item.typeId)
    if(item.isrequired && !arrFiles.includes(item.id)) {
      check = true
    }
    return check
  }
  const checkErrorFormFiles = () => {
    let tempYup = {} as any
      dataRequiredDocument?.forEach((item:any,index: number)=> {
        if(checkFile(item)) {
          let temp = {
            [item.id]: yup.array().min(1).max(item.numberoffile).required("At least a file is required")
            .test('is-correct-file', 'VALIDATION_FIELD_FILE_BIG', (value:any) => {
              return value && checkIfFilesAreTooBig(value,item.maxfilesize)
          })
          }
          tempYup = Object.assign(tempYup,temp)
        }
      })
      setYupSchemaFile(yup.object().shape(tempYup))
  }
  let columns = [{
    Header: (props: any) => <CustomHeader tableProps={props} title='#' className='w-30px' />,
    id: 'index',
    Cell: ({ ...props }) => <BasicCell className='' data={props.row.index+1} />
  }, {
    Header: (props: any) => <CustomHeader tableProps={props} title='text.request.name' className='' />,
    id: 'fileName',
    Cell: ({ ...props }) => <div>
      <a href={props.data[props.row.index].url} target="_blank" rel="noopener noreferrer">{props.data[props.row.index].fileName}</a>
    </div>,
  },
  {
    Header: (props: any) => <CustomHeader tableProps={props} title='text.document-type' className='' />,
    id: 'typeName',
    Cell: ({ ...props }) => <BasicCell className='' data={props.data[props.row.index].typeName} />,
  },
  {
    Header: (props: any) => <CustomHeader tableProps={props} title='text.size' className='' />,
    id: 'size',
    Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].size} />,
  },
  {
    Header: (props: any) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
    id: 'actions',
    Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
        {/* <RowIcon action="modify" permission={permission} to={props.data[props.row.index].id} /> */}
        {
          !checkIsDisabledForm() &&
          <RowIcon action="delete" className={clsx({'disabled opacity-75': checkIsDisabledForm()})} onClick={async () => {
          if(checkIsDisabledForm()) return
          // const typeId = props.data[props.row.index].url
            const pathtofile = props.data[props.row.index].pathtofile
          if(!pathtofile) {
            setListFiles((pre:any) => pre.filter((f:any,index: number) => index != props.row.index))
          }
          else {
            let tempArr = JSON.parse(JSON.stringify(listFiles))
            let filesDeleteTemp = JSON.parse(JSON.stringify(filesDelete))
            let pathtofile = tempArr[props.row.index].pathtofile
            filesDeleteTemp.push(pathtofile)
            setFilesDelete(filesDeleteTemp)
            setListFiles((pre:any) => pre.filter((f:any) => f.pathtofile != pathtofile))
  
          }
            
        }} />
        }
        
    </div>,
  },
  ]
  useEffect(()=>{
    if(dataRequiredDocument) {
      setRequiredDocument(dataRequiredDocument)
      checkErrorFormFiles()
    }
    
  },[dataRequiredDocument])
  const { register:registerFile, control:controlFile, resetField, reset: resetFile, handleSubmit: handleSubmitFile,getValues: getValuesFile, setValue: setValueFile, watch: watchFile, formState: { errors: errorsFile } } = useForm({ resolver: yupResolver(yupSchemaFile) })
 
  const { register, control, reset, handleSubmit,getValues, setValue, watch, formState: { errors, isSubmitting} } = useForm({ resolver: yupResolver(yupSchema) })
  const [tableData, setTableData] = useState<any>([])

  const [isCheck, setIsCheck] = useState<Array<any>>([])
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [keys, setKeys] = useState<Array<Array<any>>>([])
  const [filters, setFilters] = useState<Array<string>>([])
  const [searchData, setSearchData] = useState<any[]>([])
  function handleSearch(keys: Array<Array<any>>, data: any[], filters: string[]) {
      setKeys(keys)
      setSearchData(data)
      setFilters(filters)
  }
  const handleSelectAll = (e:any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(tableData.map((item:any,index:number) => index.toString()));
    if (isCheckAll) {
      setIsCheck([]);
      setIsShowMore(false)
    }
};

  const header = [
//     {
//     title: '',
//     class: 'text-center w-30px',
//     type: 'check',
// },
{
  title: '#',
  class: 'text-center w-30px',
},
{
  title: intl.formatMessage({ id: `text.code` as any }),
  class: 'w-60px',
},{
title: intl.formatMessage({ id: `text.description` as any }),
class: 'w-150px',
},
{
  title: intl.formatMessage({ id: `text.explain` as any }),
  class: '',
},
{
  title: `Store Code`,
  class: 'w-150px select-arrow',
},
// {
// title: `Store Name`,
// class: 'w-150px select-arrow',
// },
 {
    title: intl.formatMessage({ id: `text.uom` as any }),
    class: 'w-60px  ',
}, {
    title: intl.formatMessage({ id: `text.quantity` as any }),
    class: 'w-60px text-center ',
},  {
    title: intl.formatMessage({ id: `text.unit-price` as any }),
    class: 'text-center w-90px',
},

{
  title: '% VAT',
  class: 'text-center w-45px',
},
{
  title: intl.formatMessage({ id: `text.total` as any }),
  class: 'text-center w-100px',
},
{
  title: intl.formatMessage({ id: `text.bill-code` as any }),
  class: 'text-center w-60px',
},


]
// useEffect(() => {
//   if(watch('expenseid') && watch('status') && watch('status') == '284210000') {
//     let dataEx = getExpenseValue()
//      renderDetails([],optionsUoms,optionsStoresName,true)
//   }
// }, [watch('expenseid')])
const getExpenseValue = () => {
  let record = optionsExpenses.find((i:any)=>i.id == watch('expenseid'))||{}
  let ExpenseValues = record.ExpenseValues && record.ExpenseValues.length != 0? record.ExpenseValues.map((item:any)=>{
    return {
      // ...item,
      expensevaluecode: item.code,
      expensevalueid: item.id,
      description: item.description,
      label: item.description,
      expensename: item.expensename,
      value: item.code,
      vatperc: 10,
      
    }
  }) : [
    {
      // ...record,
      expensevaluecode: record.code,
      expensevalueid: record.id,
      description: record.expensename,
      label: record.expensename,
      expensename: record.expensename,
      value: record.code,
      vatperc: 10,
    }
  ]
   return ExpenseValues
  }
  
  
  const renderDetails = (lineDetails:any, uomArr:any,dimeArr: any, getChangeData?: boolean) => {
    resetFieldTable()
    // console.log('lineDetails',lineDetails)
    let defaultRow = [
      [ 
        { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('code').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-code' },
        { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('name').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-name' },
        { value: '', className: 'text-start' },
        { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: [], templateCell: templateCell },
        { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: [], createdSelect: true },
        { value: '', className: 'text-center' },
        { value: '', className: 'text-center' },
        { value: '', className: 'text-center' }, 
        { value: '', className: 'text-center', readOnly: true },
        { value: '', className: 'text-center' }, 
      ],
    ]
    if(lineDetails && lineDetails.length != 0) {
      let tempData = [] as any
      let readOnly = checkIsDisabledForm()
      lineDetails.forEach((item:any,index:number) => {
        let temp = [
          {
            value: item.expensevaluecode,
            id: !getChangeData ? item.id: undefined,
            index: index,
            className: 'text-start',
            dataEditor: SelectEditorPopup, 
            optionsData: optionsPayments('code'), 
            typeCell: 'custom-code',
            readOnly: readOnly,
          },
          {
            value: item.description,
            className: 'text-start',
            dataEditor: SelectEditorPopup, 
            optionsData: optionsPayments('name'), 
            typeCell: 'custom-name',
            readOnly: readOnly,
          },
          {
            value: item.remark,
            className: 'text-start',
            readOnly: readOnly,
          },
          {
            value: item.globaldimension1value,
            className: '',
            // dataEditor: SelectEditorDimension,
            templateCell: templateCell,
            optionsData: dimeArr||[],
            isAsync: true,
            readOnly: readOnly,
          },
          {
            value: item.uom,
            className: '',
            dataEditor: SelectEditorPopup,
            optionsData: uomArr||[],
            createdSelect: true,
            isAsync: true,
            readOnly: readOnly,
          },
          {
            value: !getChangeData ? formatCurrent(item.quantity): 1,
            className: 'text-center',
            readOnly: readOnly,
          },
          {
            value: formatCurrent(item.unitprice),
            className: 'text-center',
            readOnly: readOnly,
          },
          {
            value: item.vatperc,
            className: 'text-center',
            readOnly: readOnly,
          },
          {
            value: formatCurrent(item.amountinclvat),
            readOnly: true,
            className: 'text-center',
          },
          {
            value: item.invoiceno,
            className: 'text-center',
            readOnly: readOnly,
          },
        ]
        tempData.push(temp)
      });
      if(watch('status') == "284210001" || watch('status') == "284210002" ) {
        setTableData(tempData)
      }
      else {
        let a = tempData.concat(defaultRow)
        setTableData(a)
      }
  }
    else {
      setTableData(defaultRow)
    }
  }
  const resetFieldTable = () => {
    tableData.forEach((i:any,index: number)=> {
      setValue('expensevaluecode_'+index, null)
      setValue('storeid_'+index, null)
      setValue('remark_'+index, null)
    })
  }
  const getData = async(data:any) => {
    if(data) {
      data = JSON.parse(JSON.stringify(data))
    }
    setDataRequest(data)
    let dataReset = data||{
      "statuscode": 1,
      "modifiedby": null,
      "modifiedat": null,
      "createdby": null,
      "creater": user?.username,
      "createdat": [new Date()],
      "documentno": null,
      "documentdate": [new Date()],
      "documenttype": null,
      "sourceno": null,
      "sourcename": null,
      "paymentmethodcode": 284210000,
      "currencytype": 284210000,
      "employeeadvancerequestid": null,
      "employeeadvancerequestno": null,
      "employeeadvancerequest": null,
      "remainingadvancedamount": null,
      "totalamount": null,
      "expensevalueid": null,
      "expensecode": null,
      "expensename": null,
      "requireddocuments": null,
      "bankno": null,
      "bankname": null,
      "bankbranchname": null,
      "bankbranchno": null,
      "bankaccountno": null,
      "bankaccountname": null,
      "status": "284210000",
    } as any
    dataReset.documentdate = dataReset.documentdate && [new Date(dataReset.documentdate)]
    let dataAdvances = await getAdvances(dataReset.sourceno)

    // if(dataReset?.status == "284210002") {
    //   let remainingadvancedamount = Number(dataReset.remainingadvancedamount) >= 0 ? 0 : Math.abs(Number(dataReset.remainingadvancedamount))
    //   dataReset.remainingadvancedamount = remainingadvancedamount + Number(dataReset.advancedamount)
    // }
    // else {
      let recordAdvance = dataAdvances?.find((i:any)=>i.id == dataReset.employeeadvancerequestid)
      dataReset.remainingadvancedamount = recordAdvance?.remainingadvancedamount
      // console.log('dataReset.advancedamount',dataReset.advancedamount)
      // console.log('recordAdvance',recordAdvance)
      // let advancedamount = +dataReset.totalamount > +dataReset.advancedamount ? dataReset.advancedamount : dataReset.totalamount
      // dataReset.advancedamount = advancedamount
      if(dataReset.advancedamount > recordAdvance?.remainingadvancedamount && !checkIsDisabledForm() && approvalhistories) {
        // dataReset.advancedamount = recordAdvance?.remainingadvancedamoun
        // popupMessage({icon: "info", description: intl.formatMessage({ id: `text.advance-check-amount`}) })
      }
    // }
    
    // if(dataReset.documenttype == "284210001"){
    //     setValue('sourceno',user?.vendorcode)
    //     setValue('sourceid',user?.vendorid)
    //     setValue('sourcename',user?.vendorname)
    //     setValue('departmentname',user?.departmentname)
    //     setValue('departmentid',user?.departmentid)
    //     setValue('departmentcode',user?.departmentcode)
    // }
    reset(dataReset)
    if(dataReset?.documenttype) {
      if(dataReset.documenttype == '284210002') {
        if(optionsCustomers.length == 0)
        getOptionCustomers(zustandStore)
      }
      else if(optionsVenders.length == 0){
        getOptionVenders(dataReset.documenttype,zustandStore)
      }
    }
    const PaymentRequestLines = data ? data.PaymentRequestLines : []
   
    let dimeArr = optionsStoresName ||[] as any
    if(!(optionsStoresName && optionsStoresName.length != 0)) {
      dimeArr = getDimensionValues()
    }
    // console.log('dimeArr',dimeArr)
    setListFiles(data?.requireddocuments||[])
    setTimeout(()=> {
      renderDetails(PaymentRequestLines,optionUomsData, dimeArr)
    },200)
  }
  useEffect(() => {
    const funcApproval = async() => {
      if(isSuccessApprovals) {
        let tempDataLevel = null as any
        let id_request = idRequest||valueid
        if(dataApprovals && dataApprovals.length != 0) {
          const dataApprovalsSort = sortWithAny(dataApprovals,{sort:'priority',order:'asc'})
          let checkCond = true
          let groupUser = await getInforGroup(dataRequest?.createdby)||[] as any
          dataApprovalsSort.forEach((item:any) => {
            if(!checkCond) return
            if(dataRequest) {
              setEmailnotification(getEmailInfo(item.emailnotification,dataRequest,permission))
            }
            
            if(item.type != 20120002) {
              approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
              checkCond = false
              return
            }
            else {
              let tempArr = [] as any
              let criteria_string = item.rule?.criteria_string||"1" as string
              let not_rule = true as boolean
              if(item.rule && item.rule.columns && item.rule.columns.length != 0) {
                item.rule?.columns.forEach((it: any) => {
                  let item = it
                  not_rule = false
                  if(item.field == 'usergroup' && item.comparator == 'is' && id_request) {
                    let check = groupUser.find((i: any) => i.usergroupid == item.value)
                    let a = check? true: false
                    tempArr.push(a)
                  }
                  else if(dataRequest) {
                    tempArr.push(convertOperatorObject[item.comparator](dataRequest[item.field],item.value,item.datatype))
                  }
                })
              }
              if (tempArr.length == 0 && dataRequest && not_rule) {
                tempArr = [true]
              }
              // console.log('tempArr',tempArr)
              const ifCondition = convertToIfCondition(criteria_string,tempArr);
              // console.log('ifCondition',ifCondition)
              if (eval(ifCondition)) {
                // console.log("Điều kiện đúng");
                tempDataLevel = formatDataLevel(item?.approvallevels||[],dataRequest?.createdby||user?.id, item?.approvalreminderbefore)
                setApprovalLevels(tempDataLevel)
                // console.log("tempDataLevel",tempDataLevel);
                if(user?.id == dataRequest?.createdby) {
                  item.approvalid = item.id
                  setIsEditNotApproval(false)
                  setRequestUser(item)
                }
                else if (tempDataLevel?.find((i:any)=>i.userid == user?.id)){
                  item.remainingadvancedamount = watch('remainingadvancedamount')
                  item.advancedamount = dataQuery?.advancedamount
                  item.employeeadvancerequestid = watch('employeeadvancerequestid')
                  // console.log('item',item)
                  
                  setApprovalUser(item)
                }
                checkCond = false
                return
    
              } else {
                // console.log("Điều kiện sai");
                setApprovalLevels(null)
                tempDataLevel = null
                setApprovalUser(null)
                setRequestUser(null)
                setAutoApprovalRequest(false)
                if(user?.id == dataRequest?.createdby && dataRequest.status == '284210000') {
                  setIsEditNotApproval(true)
                }
              }
            }
          })
          
        }
        else {
          // console.log('else')
          tempDataLevel = await approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
        }
        if(id_request) {
          if((isEditNotApproval || (!tempDataLevel && (user?.id == dataRequest?.createdby && dataRequest.status == '284210000'))) && isSuccessApprovals) {
            popupMessage({icon: "info", description: intl.formatMessage({ id: `text.workflows-not-defined`}) })
          }
          else if(autoApprovalRequest) {
            setTimeout(()=>{refApprovalRequest?.current?.click()},100)
          }
        }
      }
    }
    if(isSuccessApprovals && !isFetchingApp && dataApprovals) {
      funcApproval()
    }
    
}, [isSuccessApprovals,isFetchingApp])
const checkTableError = (table: any) => {
  let check = false
  let classError = 'form-error'
  table.forEach((item:any,ind:number)=> {
    let storeid = 'storeid_'+ind
    let code = 'expensevaluecode_'+ind
    
    if(tableData.length > 1 && !item[0].value && !item[3].value) {
      return 
    }
  
    item.forEach((i:any,index: number)=> {
      if(index == item.length - 1 && i.value) {
        if(!checkNumberOrder(i.value)) {
          i.className = i.className?.replace(classError,'')+' '+ classError
          check = true
        }
      }
      else if(index == item.length - 1 && index > 1) {
        console.log('index',index)
        return
      }
      if(i.value === "") {
        i.className = i.className?.replace(classError,'')+' '+ classError
        check = true
      }
    })
  })
  setTableData(table)
  if(check) {
    popupMessage({icon: "error", description: intl.formatMessage({ id: `text.please-check-form-detail`}) })
  }
  return check
}
const uploadFileSubmit = async() => {
  try {

    let files = listFiles.filter((item:any)=>!item.url).map((item:any)=>item.file)
    let filesLocal = listFiles.filter((item:any)=>item.url)
    if(!files || files.length == 0) return filesLocal
    let userid = user?.id as any
    let dataFile = await ApiUploadFiles(userid,'payment-request',files)
    let tempData = [] as any
    dataFile?.forEach((item:any)=> {
      let requiredDocumentRecord = listFiles.find((i:any)=>i.fileName == item.fileName )||{}
      let temp = {
        fileName: item.fileName,
        size: item.fileSize,
        typeId: requiredDocumentRecord.typeId,
        typeName: requiredDocumentRecord?.typeName,
        url: item.url,
        pathtofile: item.pathtofile
      }
      tempData.push(temp)
    })
    setListFiles(filesLocal.concat(tempData))
    return filesLocal.concat(tempData)
  } 
  catch (error) { ApiShowError(error) }
  }
  const checkErrorFilesSubmit = () => {
    let isErrors = false
    requiredDocument.forEach((item: any) => {
     if(isErrors) return
     isErrors = checkFile(item)
    })
    if(isErrors) {
     popupMessage({icon: "error", description: intl.formatMessage({ id: `text.please-attach-Files-as-requested`}) })
    }
    return isErrors
   }

  const onSubmit = async(record: any) => {
    popupLoading()
    // console.log('tableData',tableData)
    let checkTable = checkTableError(tableData.map((row:any) => [...row]))
    if(checkTable || checkErrorFilesSubmit()) {
      return
    }
    let PaymentRequestLines = [] as any
    // console.log('optionDimensionData',optionDimensionData)
    let roundNum = checkCurrencyVND()?0:2
    tableData.forEach((item:any,index:number)=>{
      if(!item[0].value && !item[3].value) return
      let totalAmount = Number((+reFormatCurrent(item[5].value)*(+reFormatCurrent(item[6].value))*1000/1000).toFixed(roundNum))
      // console.log('totalAmount',totalAmount)
      let vatamount = (+item[7].value* totalAmount/100).toFixed(roundNum)
      let recordExpenses = getExpenseValue().find((i:any)=>i.value == item[0].value)||{}
      let recordDimesion = optionDimensionData?.find((i:any)=>i.value == item[3].value)||{code:item[3].value,name:item[3].value}
      // console.log('recordDimesion',recordDimesion)
      // console.log('item',item)
      let temp = {
        "id": undefined, //item[0].id,
        "statuscode": 1,
        "paymentrequestid": valueid,
        // "storeid": storeid,
        "globaldimension1value": recordDimesion.code,
        "globaldimension1name": recordDimesion.name,
        "expensevaluecode": recordExpenses.expensevaluecode,
        "description": recordExpenses.description,
        "expensevalueid": recordExpenses.expensevalueid,
        "remark": item[2].value,
        "uom": item[4].value,
        "quantity": reFormatCurrent(item[5].value),
        "unitprice": reFormatCurrent(item[6].value),
        "vatperc": item[7].value,
        "vatamount": +vatamount,
        "amountexclvat": totalAmount,
        "amountinclvat": Number((+vatamount+totalAmount).toFixed(roundNum)),
        "invoiceno": item[9].value,
      }  
      PaymentRequestLines.push(temp)
    })
    let tempData = JSON.parse(JSON.stringify(record))
    tempData.code = tempData.code||null
    tempData.discountpct = tempData.discountpct?tempData.discountpct:null
    tempData.guaranteepct = tempData.guaranteepct?tempData.guaranteepct:null
    tempData.id = valueid||idRequest
    tempData.statuscode = 1
    tempData.documentdate = tempData.documentdate && tempData.documentdate[0]
    tempData.createdat = undefined
    tempData.creater = undefined
    tempData.PaymentRequestLines = PaymentRequestLines
    let discountamount = tempData.discountamount||0
    let guaranteeamount = tempData.guaranteeamount||0
    tempData.totalamount = reFormatCurrent(totalAmount()) - discountamount - guaranteeamount
    tempData.totalamount = Number(tempData.totalamount.toFixed(roundNum))
    tempData.advancedamount = tempData.advancedamount //checkpayment() <= 0 ? tempData.totalamount: tempData.remainingadvancedamount
    tempData.remainingadvancedamount = tempData.remainingadvancedamount//checkpayment() > 0 ? 0 : checkpayment()
    if (!arrObject) return popupMessage({ icon: 'error' })
    if (!arrObject?.nosericode && !record.documentno) return setError(`documentno`, { type: 'validate' }, { shouldFocus: true })
    let nosericode = arrObject?.nosericode && !isEdit ? `/?nosericode=${arrObject?.nosericode}` : ''
    let chooseTypeSubmit = {} as any
    if(isHasChange && !idRequest) {
      chooseTypeSubmit = PaymentRequestLines.length == 0 ? {} 
      : 
      await popupCustomQuestion(
      { description: intl.formatMessage({ id: `text.please-quickly-select-an-action`}),
      confirmButtonText: intl.formatMessage({ id: `text.save-and-create`}),
      denyButtonText: intl.formatMessage({ id: `text.save-and-send-request`}),
        showDenyButton: !isEditNotApproval && true, 
        denyButton:"btn-success"}
      )
      if(chooseTypeSubmit.isDismissed == true) {
        return
      }
    }
    // return
    let requireddocuments = await uploadFileSubmit()
    tempData.requireddocuments = requireddocuments ? requireddocuments.filter((i:any)=> !i.isDeleted) : undefined
    try {
      if(filesDelete.length!=0) {
        await ApiService.post(`utilities/deletemultifiles`, {pathtofiles: filesDelete})
        setFilesDelete([])
      }
      setIsHasChange(false)
      const method = 'post'//isEdit ? 'put' : 'post'
      if(!idRequest) {
        let otherinfo = {
          deptid: user?.departmentid,
          deptcode: user?.departmentcode,
          deptname: user?.departmentname,
        }
        tempData.otherinfo = otherinfo
      }
      let {data} = await ApiService[method](`payments/paymentrequests/upsertwithnewdetails${nosericode}`, tempData)
      
      // let tempDataemp = {
      //   open: false,
      // }
      // data.employeeadvancerequestid && ApiService.put('employees/employeeadvancerequests' + `/${data.employeeadvancerequestid}`,tempDataemp)
      // if(data.employeeadvancerequestid != dataQuery?.employeeadvancerequestid) {
      //   dataQuery?.employeeadvancerequestid && ApiService.put('employees/employeeadvancerequests' + `/${dataQuery?.employeeadvancerequestid}`,{open: true})
      // }
      data.documentdate = data.documentdate && [new Date(data.documentdate)]
      setidRequest(data.id)
      if(valueid) {
        refetch()
      }
      else {
        getData(data)
      }
      setAutoApprovalRequest(chooseTypeSubmit.isDenied)
      // console.log('chooseTypeSubmit.isDenied',chooseTypeSubmit.isDenied)
      await dataSetupApproval()
      if(chooseTypeSubmit.isDenied) {
        setTimeout(async()=> {
          // refApprovalRequest?.current.click()
          setValue('remainingadvancedamount',data.remainingadvancedamount - data.advancedamount)

        },200)
      }
      else {
        popupMessage({ icon: 'success', autoClose: true })
      }
    } catch (error) { ApiShowError(error) }
  }

 
  const handleClickRemoveItem = (record: any) => {
    Swal.fire({
      text: `Do you want to delete this row?`,
      icon: "question",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary order-2",
        cancelButton: "btn btn-secondary bg-secondary order-1",
      },
    }).then(async (result) => {
      if(result.isConfirmed) {
        let grid = tableData.map((row:any) => [...row]) as any
        let index = grid.findIndex((i:any)=> i[0].id == record[0].id)
        grid[index][0].isDeleted = true
        setTableData([...grid])
        return
        ApiService.delete(`invoicedetails/${record[0].id}`)
        .then(res => {
          
            let grid = tableData.map((row:any) => [...row]) as any
            let index = grid.findIndex((i:any)=> i[0].id == record[0].id)
            // for(let i = index;i<=grid.length;i++) {
    
            //     setValue('type_invoice_'+index,getValues('type_invoice_'+(+index+1)))
            //     setValue('no_invoice_'+index,getValues('no_invoice_'+(+index+1)))
            //     setValue('name_invoice_'+index,getValues('name_invoice_'+(+index+1)))
            //   }
            grid[index][0].isDeleted = true
              // grid.splice(index,1)
              // if(index> -1) {
              //   setValue('type_invoice_'+index,'')
              //   setValue('no_invoice_'+index,'')
              //   setValue('name_invoice_'+index,'')
              //   grid = grid.splice(index,1)
              // }
            setTableData([...grid])
            // setTableData((current:any)=> {
            //   let tempTable = [...current]
            //   let index = tempTable.findIndex((i:any)=> i[0].id == record[0].id)
            //   if(index> -1) {
            //     setValue('type_invoice_'+index,'')
            //     setValue('no_invoice_'+index,'')
            //     setValue('name_invoice_'+index,'')
            //     tempTable.splice(index,1)
            //   }
            //   return tempTable
            // })

        })
        .catch(({response}) => {
          Swal.fire({
            icon: 'error',
            title: 'An error occurred',
            showConfirmButton: false,
            timer: 1500
          })
        })
      }
    })
    
  }
  const [isShowMore,setIsShowMore] = useState(false)
  const handleSelectMore = (index:any) => {
    setIsCheck([...isCheck, index]);
    setIsShowMore(true)
  }
  const saveDimesion = (data: any) => {
    let storeid = 'storeid_'+data.index//props.row
    setValue(storeid,data[storeid])
  }
  const autoFillData = (value:any,index:any) => {
    let tempData = getExpenseValue()?.find((item:any)=>item.value == value || item.label == value)
    const grid = tableData.map((item: any) => [...item])
    let rowDefault:any = 
        [ 
          { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('code').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-code' },
          { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('name').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-name' },
          { value: '', className: 'text-start' },
          { value: '', className: '', optionsData: [], templateCell: templateCell },
          { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: [], createdSelect: true },
          { value: '', className: 'text-center' },
          { value: '', className: 'text-center' },
          { value: '', className: 'text-center' }, 
          { value: '', className: 'text-center', readOnly: true },
          { value: '', className: 'text-center' }, 
        ]
      let rowEdit = rowDefault
      if(tempData) {
        rowEdit = [
          {
            value: tempData.value,
            id: undefined,
            index: index,
            className: 'text-start',
            dataEditor: SelectEditorPopup, 
            optionsData: optionsPayments('code').filter((i:any)=>!checkChoise(i.value)), 
            typeCell: 'custom-code'
          },
          {
            value: tempData.label,
            className: 'text-start',
            dataEditor: SelectEditorPopup, 
            optionsData: optionsPayments('name').filter((i:any)=>!checkChoise(i.value)), 
            typeCell: 'custom-name',
          },
          {
            value: grid[index][2].value,
            className: 'text-start',
          },
          {
            value: grid[index][3].value,
            className: '',
            // dataEditor: SelectEditorPopup,
            templateCell: templateCell,
            // optionsData: optionsStoresName,
          },
          {
            value: grid[index][4].value,
            className: '',
            // dataEditor: SelectEditorPopup,
            // optionsData: optionsUoms,
            createdSelect: true,
          },
          {
            value: grid[index][5].value?grid[index][5].value: 1,
            className: 'text-center',
          },
          {
            value: grid[index][6].value,
            className: 'text-center',
          },
          {
            value: grid[index][7].value?grid[index][7].value:10,
            className: 'text-center',
          },
          {
            value: grid[index][8].value,
            readOnly: true,
            className: 'text-center',
          },
          {
            value: grid[index][9].value?grid[index][9].value:index != 0 ? grid[index -1][9]?.value : "",
            className: 'text-center',
          },
        ]
        grid[index] = rowEdit
        let expenseData = optionsPayments()?.filter((i:any) => !checkChoise(value))
        if(value == '') {
          grid[index] = rowDefault
        }
        else if((grid.length == +index + 1) && expenseData.length !=0 && value!= '') { // add new row
          grid.push(rowDefault)
        }
        return grid
      }
      // grid[index] = rowEdit
      // let expenseData = optionsPayments()?.filter((i:any) => !checkChoise(value))
      // if(value == '') {
      //   grid[index] = rowDefault
      // }
      // else if((grid.length == +index + 1) && expenseData.length !=0 && value!= '') { // add new row
      //   grid.push(rowDefault)
      // }
      return []//grid

  }
  const totalAmountIncludeVAT = (item:any) => {
    let roundNum = checkCurrencyVND()?0:2
    let total = Number((+reFormatCurrent(item[5].value) * +reFormatCurrent(item[6].value)).toFixed(roundNum))
    let vat = (+item[7].value * total)/100
    vat = Number(vat.toFixed(roundNum))
    return formatCurrent((total+vat).toFixed(roundNum))
  }
  const clearRowTable = (row:any) => {
    if(tableData.length > 1) {
      setTableData((pre: any) => pre.filter((f: any, index: any) => index != row))
    }
    // autoFillData('',row)
  }
  const schemaChild = yup.object({
    
    // ses_weighting: yup.number().required(),
}).required();
  const { register:registerChild, reset:resetChild,setValue:setValueChild,resetField: resetFieldChild,setError:setErrorChild, handleSubmit:handleSubmitChild, control:controlChild, watch:watchChild,setError, formState: { errors:errorsChild } } = useForm<any>({ resolver: yupResolver(schemaChild) })
  const [isShowFiles, setIsShowFile] = useState<boolean>(false)
  const handleCloseModalFile = () => {
    setIsShowFile(false)
  }
  const handleAddModalFile = () => {
    setIsShowFile(true)
    setTimeout(()=>{
      requiredDocument?.forEach((item:any)=> {
        setValueFile(item.id,returnFileValues(item.id))
        checkErrorFormFiles()
      })
    },100)
  }

  const [isShowImport, setIsShowImport] = useState<boolean>(false)
  const handleCloseModalImport = () => {
    setIsShowImport(false)
  }
  const handleAddModalImport = () => {
    if(checkIsDisabledForm()) return
    setIsShowImport(true)
    
  }
  const returnFileValues = (typeId:string) => {
    return listFiles.filter((i:any)=> i.typeId == typeId && i.state != 'remove').map((i:any)=>({...i,fileSize: i.size}))
  }
  const templateTableCell = (className: any) => {
    let data = [] as any
    tableData?.forEach((i: any) =>{
      let temp = i as any
      temp.forEach((item: any,index: number) => {
        if(index != 8)
        item.readOnly = checkIsDisabledForm()
        if(index == 0) {
          item.optionsData = optionsPayments('code')
          item.dataEditor = SelectEditorPopup
        }
        if(index == 1) {
          item.optionsData = optionsPayments('name')
          item.dataEditor = SelectEditorPopup
        }
      });
      data.push(temp)
    })||[]

    return data.length !=0 && <ReactDataSheet
    className={clsx("table-manual w-100",className)}
    data={data}
    valueRenderer={(cell:any) => cell.value}
    onCellsChanged={changes => {
    let grid = data.map((row:any) => [...row]) as any
    // console.log('grid',grid)
    let colChange = null as any
    let classError = 'form-error'
    setIsHasChange(true)
    changes.forEach(({ cell, row, col, value }) => {
        // console.log('onCellsChanged value',value)
        let className = grid[row][col].className||''
        if(col == 9 && value) {
          value = checkNumberOrder(value)
          
        }
        if(col == 5 || col == 6 || col== 8) {
          value = reFormatCurrent(value) as any
          // value = formatCurrent(Number(value))
          // console.log('value',value)
          value = isNaN(value as any)?'' : formatCurrent(Number(value))
          // console.log('value',value)
        }
        if(!value && col != 7 && col != 9) {
          className = className.replace(classError,'')+' '+ classError
        }
        else {
          className = className.replace(classError,'')
        }
        colChange = col
        if(col == 0) {
          value = optionsPayments('code').filter((i:any)=>!checkChoise(i.value)).find((i: any) => i.label == value)?.label
        }
        if(col == 1) {
          value = optionsPayments('name').filter((i:any)=>!checkChoise(i.value)).find((i: any) => i.label == value)?.label
        }
        // console.log('value',value)
        grid[row][col] = { ...grid[row][col], value,className };
        grid[row][8] = {value: totalAmountIncludeVAT(grid[row]),readOnly: true, className:"text-center"}
        if((col == 0 || col == 1) && value) {
          let a = autoFillData(value,row)
          if(a.length != 0) {
            grid = a
          }
        }
       
    });
      
    // }
    setTableData([...grid])
    // setTableData((pre: any,index: any) => grid[index])
   
    }}
    sheetRenderer={(props:any) => (
      <table className={clsx(props.className,{'select-more':isShowMore})}>
          <thead className="bg-light">
              <tr>
                  {header.map((cell:any, index:number) => (
                      <th rowSpan={cell.rowSpan} colSpan={cell.colSpan}
                      key={'header' + index} className={clsx(cell.class,{'wrap-checkinvoice':cell.type == 'check'})} >
                        {cell.type == 'check' ?
                          <div className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input m-auto" type="checkbox" checked={isCheckAll} value='all' onChange={handleSelectAll} id='checkAll' />
                          </div>
                          :
                          <span>{cell.title}</span> 
                        }
                          
                      </th>

                  )
                  )}
              </tr>
          </thead>
          <tbody>
              {props.children}
          </tbody>
      </table>
    )}
    rowRenderer={(props:any) => {
      // let grid = JSON.parse(JSON.stringify(tableData)).filter((i:any)=>!i[0].isDeleted)
      // let number = grid.findIndex((i:any)=>i[0].index == tableData[props.row][0].index).toString()
      const item = tableData[props.row]||{}
      // console.log('item',item)
      let index = props.row.toString()
      
      return <tr className={clsx({'tr-cell-custom': !checkIsDisabledForm()},{'d-none': item[0].isDeleted},)}>
          <td className='hover-tr'>
            {
              (item[0].value != '' || item[1].value != '') && <>
                <div className='text-center stt-number'>{+index+1}</div>
                {
                  !checkIsDisabledForm() &&
                  <span className='clear-row-cell text-center' onClick={e=>clearRowTable(index)}><i className="bi bi-x-circle text-danger fw-bold"></i></span>
                }
              </>
            }
            
          </td>
          
          {props.children}
          
      </tr>
    }}
    
    cellRenderer={(props: any) => {
      const { onDoubleClick, children, className } = props
      const {
        as: Tag, cell, row, col, columns, attributesRenderer,
        selected, editing, updated, style,
        ...rest
        // ...attributes
      } = props
    //  console.log('memoizedValue',memoizedValue)
      return <td {...rest} className={clsx({'custom-cell': col == 3 || col == 4},className)}>
        {children}
        {(col == 3 || col == 4) && !checkIsDisabledForm() && 
        <span onClick={(e:any) => {
          let array = col == 3 ? optionDimensionData : optionUomsData
          // console.log('array',array)
          // e.preventDefault()
          // handleChange(_input?.current)
          handleSearch(
          [
              ['value'],
              ['label'],
              ['props'],
          ],
          array.map((i: any) =>({...i,title: i.label,props: props})),
          ['title', 'description'],
          )}
          } className='event-cell-popup'><i className="bi bi-chevron-down"></i></span>
        
        }
      </td>
    }}
  />
  }
  const onSubmitFile = async(record: any) => {
    let tempData = [] as any
    let tempDelete = [] as any
    
    for (const [key, value] of Object.entries(record)) {
      let values = [] as any
      if(value){
        values = Object.values(value as any) as any
      }
      values?.forEach((item:any)=> {
        let tempPre = item.fileName.split('.')
        let temp = {
          fileName: [removeAccents(tempPre[0]),tempPre[1]].filter((f: any) => f).join('.'),
          size: item.size && !isNaN(item.size) ? formatBytes(item.size): item.size,
          typeId: key,
          typeName: requiredDocument.find((item:any)=> item.id == key)?.description,
          file: item,
          url: item.url? item.url:null,
          pathtofile: item.pathtofile? item.pathtofile: null
        }
        if(item.state == 'remove') {
          if(item.pathtofile) {
            tempDelete.push(item.pathtofile)
          }
          return
        }
        else {
          tempData.push(temp)
        }
      })
      
    }
    let dFiles = JSON.parse(JSON.stringify(filesDelete)).concat(tempDelete)
    setFilesDelete(unique_arr(dFiles))
    setListFiles(tempData)
    handleCloseModalFile()
  }
 
  const [tab, setTab] = useState('info')
  const tabs = [
    { id: 'info', name: intl.formatMessage({ id: `text.payment-details` as any }) },
    { id: 'files', name: intl.formatMessage({ id: `text.attachments` as any }) },
  ]
  const getInfoBank = () => {
    let arr = arrBank()
    // console.log('arr',arr)
      if(arr.length != 0  && watch('paymentmethodcode') == "284210001") {
        setValue('bankbranchno',arr[0].bankbranchno)
        setValue('bankbranchname',arr[0].bankbranchname)
        setValue('bankno',arr[0].code)
        setValue('bankname',arr[0].bankname)
        setValue('bankaccountno',arr[0].bankaccountno)
        setValue('bankaccountname',arr[0].bankaccountname?arr[0].bankaccountname: watch('sourcename'))
      }
      else {
        setValue('bankbranchno',null)
        setValue('bankbranchname',null)
        setValue('bankno',null)
        setValue('bankname',null)
        setValue('bankaccountno',null)
        setValue('bankaccountname',null)
      }
  }
  const arrBank = () => {
    let arr = [] as any
    let record = renderOptionObjectCodeVal().find((i:any)=>i.number && i.number == watch('sourceno'))|| {}
    arr = record.VendorBankAccounts

    if(watch('documenttype') == '284210002') {
      record = renderOptionObjectCodeVal().find((i:any)=>i.customerno && i.customerno == watch('sourceno'))|| {}
      arr = record.CustomerBankAccounts
    }
    // console.log('arr',arr)
    if(arr) {
      arr = arr.map((e: any) => ({...e,label: e.code,value: e.code, title: e.bankname, description: [e.code, e.bankname], }))
      arr = sortWithDate(arr,{sort:'createdat', order: 'desc'})
    }
    
    return arr||[]
  }
  const totallineitemAmount = () => {
    let total = tableData.filter((item:any)=> item[5].value != '' && !item[0].isDeleted).reduce(function (acc:any, obj:any) { return acc + +reFormatCurrent(obj[5]?.value)*+reFormatCurrent(obj[6]?.value) }, 0)
    let roundNum = checkCurrencyVND()?0:2
    return formatCurrent(Number(total.toFixed(roundNum)))
  }
  const totallineitemVatAmount = () => {
    let total = tableData.filter((item:any)=> item[6].value != '' && !item[0].isDeleted).reduce(function (acc:any, obj:any) { return acc + obj[7]?.value*+reFormatCurrent(obj[6]?.value)*+reFormatCurrent(obj[5]?.value)/100 }, 0)
    let roundNum = checkCurrencyVND()?0:2
    
    return formatCurrent(Number(total.toFixed(roundNum)))
  }
  const [totalAmtGross,setTotalAmtGross] = useState(0)
  const checkCurrencyVND = () => {
    return watch('currencytype') == 284210000
  }
  const totalAmount = () => {
    let total = reFormatCurrent(totallineitemAmount()) + reFormatCurrent(totallineitemVatAmount())
    let roundNum = checkCurrencyVND()?0:2
    total = Number(total.toFixed(roundNum))
    return formatCurrent(total)
  }
  const caculateGuarantee = (type: string) => {
    let totalDiscount = watch('discountamount')||0
    let total = totalAmtGross - totalDiscount

    if(type == 'pct') {
      let amount = (total* watch('guaranteepct')/100)||null as any
      if(amount && amount.toString().split('.').length == 2) {
        amount = amount.toFixed(2)
      }
      setValue('guaranteeamount',amount)
    }
    else if(type == 'amount') {
      let amount = watch('guaranteeamount')||0
      let percent = (+reFormatCurrent(amount)*100/total)||null as any
      if(percent && percent.toString().split('.').length == 2) {
        percent = percent.toFixed(2)
      }
      setValue('guaranteepct',percent)
    }
   
  }
  const caculateDiscount = (type: string) => {
    let totalAmt = totalAmtGross
    if(!totalAmt) {
      setValue('discountpct',null)
      setValue('discountamount',null)
      setValue('guaranteepct',null)
      setValue('guaranteeamount',null)
      return
    }
    if(type == 'pct') {
      let amount = (totalAmt* watch('discountpct')/100)||null as any
      if(amount && amount.toString().split('.').length == 2) {
        amount = +amount.toFixed(2)
      }
      setValue('discountamount',amount)
    }
    else {
      let amount = watch('discountamount')||0
      let percent = (+reFormatCurrent(amount)*100/totalAmt)||null as any
      if(percent && percent.toString().split('.').length == 2) {
        percent = percent.toFixed(2)
      }
      setValue('discountpct',percent)
    }
    if(watch('guaranteepct')) {
      caculateGuarantee('pct')
    }
    return true
  }
  useEffect(()=> {
    setTotalAmtGross(reFormatCurrent(totalAmount()))
    // if(!reFormatCurrent(totalAmount())) {
    //   setValue('discountpct',null)
    //   setValue('guaranteepct',null)
    // }
  },[totalAmount])
  useEffect(()=> {
    if(watch('discountpct')) {
      caculateDiscount('pct')
    }
    else if(watch('guaranteepct')) {
      caculateGuarantee('pct')
    }
  },[totalAmtGross])
  const optionsPayments = (type?: string) => {
    let data = getExpenseValue()
    if(type == 'code') {
      data = data.map((item:any)=>(
        {
          ...item,
          label: item.expensevaluecode,
        }
      ))
    }
    // console.log('data',data)
    return data//?.filter((i:any) => !checkChoise(i.value))
  }
  const checkChoise = (value:any) => {
    return false // thông báo dc quyền chọn trùng cho nhiều store 14/10/2023
    const grid = tableData.map((row:any) => [...row]);
    let arr = grid.filter((item:any)=>item[0] != '').map((i:any,index:number)=>getValues('expensevaluecode_'+index))
    // console.log('arr',arr)
    return arr.filter((i:any)=>i && i!='').includes(value)
  }
  const checkpayment = () => {
    let advancedamount = watch('advancedamount')? watch('advancedamount'): 0 
    let discountamount = watch('discountamount')||0
    let guaranteeamount = watch('guaranteeamount')||0
    let total = reFormatCurrent(totalAmount()) - discountamount - guaranteeamount
    let roundNum = checkCurrencyVND()?0:2
    return +(total - Number(advancedamount)).toFixed(roundNum)
  }
  const downloadFileExample = () => {
    let tempData = [] as any
    let fields = [] as any
    let head = {} as any
    header.forEach((i: any,index: number) => {
      if(index == 9 || index == 2) return
      if(i.title != "#") {
        head[i.title] = ""
        fields.push(i.title)
      }
    })
    
  tempData.push(head)
    let fileName = 'request-payment-import-details_'+new Date().toISOString().slice(0,10)+'.csv'
    exportCSV(tempData,fileName, fields)
  }

 
  //===========================
  
  //===========================
  const toolbar = <>
    <KTButton action="back" onClick={() => navigate(-1)}/>
  </>
  const renderOptionObjectCodeVal = () => {
    let options = []
    if(watch('documenttype') == "284210000") {
      options = optionsVenders
    }
    if(watch('documenttype') == "284210001") {
      options = optionsEmployees
    }
    if(watch('documenttype') == "284210002") {
      options = optionsCustomers
    }
    return options
  }
  return <>
    <ContentHeader title={intl.formatMessage({ id: `text.payment-details` as any })} items={[{ title: intl.formatMessage({ id: `text.list-of-payment` as any }), path: '/requests/payment' }]} elements={toolbar}/>
    <div className='card'>
      <div className='card-header border-0 pt-6 mb-0 min-h-10px'>
        <div className='card-title'>
            <h5>{intl.formatMessage({ id: `text.general-information` as any })}</h5>
        </div>
        <div className='card-toolbar gap-2'>
          {valueid &&
          
            <TextFormByUser data={watch()} />
          }
            {
              (approvalLevels || isEditNotApproval) && 
              <ActionApprovals 
                requestUser={requestUser}
                approvalUser={approvalUser}
                approvalhistories={approvalhistories}
                approvalLevels={approvalLevels}
                refApprovalRequest= {refApprovalRequest}
                totalamount={reFormatCurrent(totalAmount ())||+dataRequest?.totalamount}
                refetchApproval={refetchApproval}
                setValue={setValue}
                watch={watch}
                valueid={idRequest}
                isHasChange={isHasChange}
                refSaveBtn={refSaveBtn}
                tableData={tableData}
                emailnotification={emailnotification}
                dataQuery={dataQuery}
                urlApi={
                  {
                    settings: 'settings',
                    typeRequest: 'payments/paymentrequests',
                    sendMail: 'utilities/sendmail/withcustomtemplate',
                    printPdf: 'print-payment',
                    request: 'payment'
                  }
                }
                employeeAdvanceData= {
                  {
                    employeeadvancerequestid: dataQuery?.employeeadvancerequestid,
                    remainingadvancedamount: watch('remainingadvancedamount'),
                    advancedamount: dataQuery?.advancedamount,
                  }
                }
                />
          }
          
        </div>
      </div>
    </div>
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className='card mb-6'>
          <div className="row card-body pt-3">
            <div className="col-md-6 col-12">
              <KTFormItem title='text.document-code' isRequired={!(arrObject?.nosericode && !idRequest)}>
                <input readOnly={(arrObject?.nosericode && !idRequest) || (checkIsDisabledForm()) } className={clsx('form-control form-control-sm', {'form-control-solid': ((arrObject?.nosericode && !idRequest) || checkIsDisabledForm()) , 'form-error': errors.documentno })} {...register('documentno')} />
              </KTFormItem>
              <KTFormItem title='text.document-date' isRequired>
                <Controller
                  name='documentdate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.documentdate })}
                      value={value}
                      onChange={onChange}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.payment-object' isRequired>
                <Controller
                  name='documenttype'
                  control={control}
                  render={({ field }) => <InputSelect
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.documenttype })}
                    disabled={checkIsDisabledForm()}
                    options={optionsVendersType}
                    value={optionsVendersType.find((e: any) => e.value == field.value)}
                    onChange={e => {
                      if(e.value != watch('documenttype')) {
                        field.onChange(e.value)
                        if(e.value == "284210001" && !paymentrequestforsomeone) {
                          setValue('sourceno',user?.vendorcode)
                          setValue('sourceid',user?.vendorid)
                          setValue('sourcename',user?.vendorname)
                          setValue('departmentname',user?.departmentname)
                          setValue('departmentid',user?.departmentid)
                          setValue('departmentcode',user?.departmentcode)
                          
                          
                        }
                        else {
                          setValue('sourceno',null)
                          setValue('sourceid',null)
                          setValue('sourcename',null)
                          setValue('departmentname',null)
                          setValue('departmentid',null)
                          setValue('departmentcode',null)
                          
                        }

                        if(e.value == "284210001" ) {
                          let schema1 = Object.assign(schema,{departmentid: yup.string()
                            .required('Input is required')})
                          setYupSchema(yup.object().shape(schema1))
                          if(optionsEmployees.length == 0) {
                            getOptionVenders(e.value,zustandStore)
                          }
                        }
                        else {
                          setYupSchema(yup.object().shape(schema))
                          if(e.value == "284210000") {
                            if(optionsVenders.length == 0) {
                              getOptionVenders(e.value,zustandStore)
                            }
                          }
                          else {
                            if(optionsCustomers.length == 0) {
                              getOptionCustomers(zustandStore)
                            }
                          }
                          
                        }
                        setValue('paymentmethodcode',284210000)
                        getAdvances(watch('sourceno'))
                        getInfoBank()
                      }
                    }}
                  />} />
              </KTFormItem>
              <KTFormItem title='text.payment-objectcode' isRequired>
                {
                  watch('documenttype') != "284210002" ? 
                  <Controller
                    name={'sourceno'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={renderOptionObjectCodeVal()}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={ renderOptionObjectCodeVal().map((i: any) =>( {value: i.number,label: i.number} ))
                      .find((e: any) => e.value == field.value)|| ''}
                        onChange={async(e: any) => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let a = renderOptionObjectCodeVal()
                        let record = a.find((i: any) => i.number == e.value)
                        if(record) {
                          // setValue('sourceno', record.number)
                          setValue('sourcename', record.vendorname)
                          setValue('departmentname', record.departmentname)
                          setValue('departmentid', record.departmentid)
                          setValue('departmentcode', record.departmentcode)
                          if(watch('documenttype') == '284210001') {
                            let data = await getInforUser(record.id)
                            if(data && data.length != 0) {
                              let record = data[0]
                              setValue('sourcetitle', record.jobtitle)
                              setValue('departmentname', record.departmentname)
                              setValue('departmentid', record.departmentid)
                              setValue('departmentcode', record.departmentcode)
                            }
                          }
                        }
                        getInfoBank()
                        getAdvances(watch('sourceno'))
                      }
                    }
                    />} />
                      :
                  <Controller
                    name={'sourceno'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={optionsCustomers}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={optionsCustomers.map((i: any) =>( {value: i.customerno,label: i.customerno} ))?.find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let record = optionsCustomers.find((i: any) => i.customerno == e.value)
                        if(record) {
                          // setValue('sourceno', record.customerno)
                          setValue('sourcename', record.customername)
                        }
                        getInfoBank()
                        getAdvances(watch('sourceno'))
                      }
                    }
                    />} />
                }

              </KTFormItem>
              <KTFormItem title='text.payment-objectname'>
                <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.sourcename })} {...register('sourcename')} />
              </KTFormItem>
              {
                watch('documenttype') == "284210001" && 
                <>
                <KTFormItem title='text.department-name' isRequired>
                  <Controller
                    name={'departmentid'}
                    control={control}
                    render={({ field }) => <InputSelect
                      options={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))?.find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let record = optionsDepts.find((i: any) => i.value == e.value)
                        if(record) {
                          setValue('departmentcode', record.departmentcode)
                          setValue('departmentname', record.departmentname)
                        }
                      }
                    }
                    />} />
                  {/* <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.departmentname })} {...register('departmentname')} /> */}
                </KTFormItem>
                <KTFormItem title='text.department-code' >
                  <Controller
                    name={'departmentid'}
                    control={control}
                    render={({ field }) => <InputSelect
                      options={optionsDepts}
                      disabled
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': true, 'form-error': errors.departmentid })}
                      value={optionsDepts?.find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let record = optionsDepts.find((i: any) => i.value == e.value)
                        if(record) {
                          setValue('departmentcode', record.departmentcode)
                          setValue('departmentname', record.departmentname)
                        }
                      }
                    }
                    />} />
                  {/* <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.departmentcode })} {...register('departmentcode')} /> */}
                </KTFormItem>
                
                </>
              }
              <KTFormItem title='text.advance-code'>
                {/* <input
                  disabled={checkIsDisabledForm()}
                  readOnly
                  className={clsx('form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.employeeadvancerequestno })} {...register('employeeadvancerequestno')}
                  onChange={(e:any) => {
                      // console.log('ádadasa')
                    }}
                    onClick={() => handleSearch(
                      [
                          ['employeeadvancerequestid','id'],
                          ['employeeadvancerequestno','documentno'],
                          ['employeeadvancerequest','description1'],
                          ['remainingadvancedamount','remainingadvancedamount'],
                          ['advancedamount','remainingadvancedamount'],
                          
                      ],
                      optionsAdvances,
                      ['title', 'description'])} /> */}
                  <Controller
                    name={'employeeadvancerequestno'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={optionsAdvances.map((i: any) =>( {value: i.documentno,label: i.title} ))}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.employeeadvancerequestno })}
                      value={optionsAdvances.map((i: any) =>( {value: i.documentno,label: i.documentno} ))
                      .find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let a = optionsAdvances
                        let record = a.find((i: any) => i.documentno == e.value)
                        if(record) {
                          // setValue('employeeadvancerequestno', record.documentno)
                          setValue('employeeadvancerequest', record.description1)
                          setValue('remainingadvancedamount', record.remainingadvancedamount)
                          setValue('advancedamount', record.remainingadvancedamount)
                        }
                        let tempData = employeeAdvance.filter((item: any) => (item.id != watch('employeeadvancerequestid') && item.open != false) || (item.id == dataQuery?.employeeadvancerequestid && dataQuery?.employeeadvancerequestid != watch('employeeadvancerequestid')))
                        setOptionsAdvances(formatOptionAdvance(tempData))
                      }
                    }
                    />} />
              </KTFormItem>
              <KTFormItem title='text.advance-content'>
                <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.employeeadvancerequest  })} {...register('employeeadvancerequest')} />
              </KTFormItem>
              <KTFormItem title='text.advance-amount'>
                <Controller
                  name="advancedamount"
                  control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                      value={value}
                      disabled={!watch('employeeadvancerequestno') || checkIsDisabledForm()}
                      onChange={(e: any) => {
                        onChange(e)
                        // console.log('e',e)
                        // let tempAmount = watch('remainingadvancedamount') > totalAmount()
                        if(e > watch('remainingadvancedamount')) {
                          setValue('advancedamount',watch('remainingadvancedamount'))
                        }

                      }}
                      className={clsx('form-control form-control-sm ',{'form-error': errors.advancedamount, "form-control-solid": !watch('employeeadvancerequestno') || checkIsDisabledForm()})} />}
                    />
              </KTFormItem>
              <KTFormItem title='text.remaining-advance-payment'>
                <Controller
                  name="remainingadvancedamount"
                  control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                      value={value}
                      disabled
                      onChange={(e: any) => {
                        onChange(e)

                      }}
                      className={clsx('form-control form-control-sm form-control-solid',)} />}
                    />
              </KTFormItem>
              <KTFormItem title='text.date-created'>
                <Controller
                  name='createdat'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled
                      className={clsx('form-control form-control-sm w-100 form-control-solid')}
                      value={value}
                      
                      onChange={onChange}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.creator'>
                <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.creater })} {...register('creater')} />
              </KTFormItem>
            </div>
            <div className="col-md-6 col-12">
              <h5 className='py-5 mb-1'>{intl.formatMessage({ id: `text.payment-information` as any })}</h5>
              <KTFormItem title='text.payment-code' isRequired labelWidth='160'>
                {/* <input
                  className={clsx('form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(),'form-error': errors.expensecode })} {...register('expensecode')}
                  readOnly
                  disabled={checkIsDisabledForm()}
                    onClick={() => handleSearch(
                      [
                          ['expensecode','code'],
                          ['expenseid','id'],
                          ['expensename','expensename'],
                          ['description','description'],
                      ],
                      optionsExpenses,
                      ['title', 'description'])} /> */}
                <Controller
                    name={'expenseid'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={optionsExpenses.map((i: any) =>( {value: i.id,label: i.code+'/ '+i.expensename} ))}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={optionsExpenses.map((i: any) =>( {value: i.id,label: i.code} ))
                      .find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let a = optionsExpenses
                        let record = a.find((i: any) => i.id == e.value)
                        if(record) {
                          setValue('expensecode', record.code)
                          setValue('expensename', record.expensename)
                          setValue('description', record.description)
                        }
                        if(watch('expenseid') && watch('status') && watch('status') == '284210000') {
                          renderDetails([],optionsUoms,optionsStoresName,true)
                        }
                      }
                    }
                    />} />
              </KTFormItem>
              <KTFormItem title='text.payment-content' className='align-items-baseline mb-4' isHeight isRequired labelWidth='160'>
                <textarea disabled={checkIsDisabledForm()} className={clsx('form-control form-control-sm resize-none', {'form-control-solid': checkIsDisabledForm(), 'form-error': errors.expensename  })} {...register('expensename')} />
              </KTFormItem>
              <KTFormItem title='text.payment-method' isRequired labelWidth='160'>
                <Controller
                  name='paymentmethodcode'
                  control={control}
                  render={({ field }) => <InputSelect
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.paymentmethodcode })}
                    options={Object.values(LocalData.paymentMethod)?.map((i:any) => ({
                      ...i,
                      label: i && i['label'+selectedLang]
                    }))}
                    disabled={checkIsDisabledForm()}
                    value={Object.values(LocalData.paymentMethod)?.find((e: any) => e.value == field.value)}
                    onChange={e => {
                        field.onChange(e.value)
                        if(watch('paymentmethodcode') == "284210000") {
                          setValue('bankname',null)
                          setValue('bankno',null)
                          setValue('bankaccountno',null)
                          setValue('bankaccountname',null)
                          setValue('bankbranchno',null)
                          setValue('bankbranchname',null)
                        }
                        else {
                          getInfoBank()
                        }
                      }
                    }
                  />} />
              </KTFormItem>
              <KTFormItem title='text.currencytype' isRequired labelWidth='160'>
                <Controller
                  name='currencytype'
                  control={control}
                  render={({ field }) => <InputSelect
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.paymentmethodcode })}
                    options={Object.values(LocalData.currencyMethod)?.map((i:any) => ({
                      ...i,
                      label: i && i['label'+selectedLang]
                    }))}
                    disabled={checkIsDisabledForm()}
                    value={Object.values(LocalData.currencyMethod)?.find((e: any) => e.value == field.value)}
                    onChange={e => {
                        field.onChange(e.value)
                      }
                    }
                  />} />
              </KTFormItem>
              {
                 true && 
                <div>
                <h5 className='py-4'>{intl.formatMessage({ id: `text.bank-information` as any })}</h5>
                <KTFormItem title='text.bank-code' labelWidth='160'>
                  <Controller
                    name={'bankno'}
                    control={control}
                    render={({ field }) => <InputSelect
                      options={arrBank()}
                      disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankno })}
                      value={arrBank()?.find((e: any) => e.value == field.value)||''}
                      onChange={e => {
                          // console.log('e',e)
                          field.onChange(e.value)
                          let record = arrBank().find((i:any)=>i.value == e.value)
                          if(record) {
                            setValue('bankname',record?record.bankname:null)

                            setValue('bankaccountno',record?record.bankaccountno:null)
                            setValue('bankaccountname',record.bankaccountname?record.bankaccountname:watch('sourcename'))

                            setValue('bankbranchno',record?record.bankbranchno:null)
                            setValue('bankbranchname',record?record.bankbranchname:null)
                            
                          }
                        }
                      }
                      defaultValue=""
                    />} />
                </KTFormItem>
                <KTFormItem title='text.bank-name' labelWidth='160'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankname })} {...register('bankname')} />
                </KTFormItem>
                <KTFormItem title='text.bank-account-no' labelWidth='160'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankaccountno })} {...register('bankaccountno')} />
                </KTFormItem>
                <KTFormItem title='text.bank-account-name' labelWidth='160'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankaccountname })} {...register('bankaccountname')} />
                </KTFormItem>
                <KTFormItem title='text.status' labelWidth='160'>
                  {LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)] && 
                    <span className={clsx(`badge badge-${LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]?.badge}`)}>
                      {LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]['label'+selectedLang]}
                    </span>
                  }
                  
                </KTFormItem>
              </div>
              }
              
              
            </div>
          </div>
        </div>
        <div className="card">
          <div className='card-header border-0'>
            <div className='card-title'>
              <div
                  className='flex-fill d-flex flex-row hover-scroll-overlay-x px-0 mb-3'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-width='auto'
                  data-kt-scroll-offset='0'
                >
                  {tabs.map((item, index) => {
                    return (<span
                      key={index}
                      id={item.id}
                      onClick={() => setTab(item.id)}
                      className={clsx(
                        'p-3 py-5 fw-bold cursor-pointer fs-5',
                        tab != item.id && 'text-black',
                        tab == item.id && 'text-primary border-bottom border-2 border-primary',
                        index == 0 && 'ps-0'
                      )}>
                      {item.name}
                    </span>)
                  })}
                </div>
            </div>
            <div className='card-toolbar'>
              {/* <span className={clsx('btn btn-sm btn-primary')} onClick={handleClickAdd}>Thêm mới</span> */}
            </div>
          </div>
          <div className="card-body pt-0">
          {
              tab == 'info' &&
              <>
              <div className='custom-btn-add-file d-flex flex-end pb-8 pt-6 '>
                {
                  <KTButton action="import" disabled= {checkIsDisabledForm()} onClick={handleAddModalImport} titleBtn={intl.formatMessage({ id: `text.import.title` as any })}/>
                }
              </div>
              {
                templateTableCell('')
                // requestUser ?
                // templateTableCell(''):templateTableCell('disabled-1')

              }
              
              <div className="row mt-5">
                <div className='col-lg-4 col-12'>
                  <KTFormItem title='text.discount-rate' labelWidth=''>
                    <input disabled={checkIsDisabledForm()} className={clsx('hide-appearance form-control form-control-sm', {'form-control-solid': checkIsDisabledForm(), 'form-error': errors.discountpct })} {...register('discountpct', {
                      onChange: (e) => {
                        if((+e.target.value > 100 || +e.target.value < 0) || isNaN(e.target.value) ) {
                          setValue('discountpct',null)
                        }
                        caculateDiscount('pct')
                      },
                    })} />
                  </KTFormItem>
                  <KTFormItem title='text.discount-value' labelWidth=''>
                    <Controller
                    name="discountamount"
                    control={control}
                      render={({ field: { value, onChange } }) => <InputPrice
                        value={value}
                        onChange={e => {
                          onChange(e)
                          caculateDiscount('amount')
                        }}
                        disabled={checkIsDisabledForm()}
                        className={clsx('form-control form-control-sm',{'form-control-solid': checkIsDisabledForm(),'form-error': errors.discountamount })} />}
                      />
                  </KTFormItem>
                </div>
                <div className='col-lg-4 col-12'>
                  <KTFormItem title='text.warranty-rate' labelWidth=''>
                    <input disabled={checkIsDisabledForm() } className={clsx('hide-appearance form-control form-control-sm', {'form-control-solid': checkIsDisabledForm(), 'form-error': errors.guaranteepct })} {...register('guaranteepct', {
                      onChange: (e) => {
                        if(+e.target.value > 100 || +e.target.value < 0 || isNaN(e.target.value)) {
                          setValue('guaranteepct',null)
                        }
                        caculateGuarantee('pct')
                      },
                    })} />
                  </KTFormItem>
                  <KTFormItem title='text.warranty-value' labelWidth=''>
                    <Controller
                    name="guaranteeamount"
                    control={control}
                      render={({ field: { value, onChange } }) => <InputPrice
                        value={value}
                        onChange={e => {
                          onChange(e)
                          caculateGuarantee('amount')
                        }}
                        disabled={checkIsDisabledForm()}
                        className={clsx('form-control form-control-sm',{'form-control-solid': checkIsDisabledForm(),'form-error': errors.guaranteeamount })} />}
                      />
                  </KTFormItem>
                </div>
                <div className=' col-lg-4 col-12'>
                  <div className='d-flex gap-5 flex-end'>
                    <span className='text-black '>{intl.formatMessage({ id: `text.total` as any })} ({intl.formatMessage({ id: `text.excluding-vat` as any })})</span>
                    <strong className=' fs-5 fw-bold min-w-100px text-end'>{totallineitemAmount()}</strong>
                  </div>
                  <div className='d-flex gap-5 flex-end'>
                    <span className='text-black '>{intl.formatMessage({ id: `text.vat` as any })}</span>
                    <strong className=' fs-5 fw-bold min-w-100px text-end'>{totallineitemVatAmount()}</strong>
                  </div>
                  <div className='d-flex gap-5 flex-end'>
                    <span className='text-black '>{intl.formatMessage({ id: `text.advance` as any })}</span>
                    {/* <strong className=' fs-5 fw-bold min-w-100px text-end'>{formatCurrent(watch('status') == "284210002" ? watch('advancedamount') : watch('remainingadvancedamount'))}</strong> */}
                    <strong className=' fs-5 fw-bold min-w-100px text-end'>{formatCurrent(watch('advancedamount'))}</strong>
                  </div>
                  <div className='d-flex gap-5 flex-end'>
                    <span className='text-black '>
                      {intl.formatMessage({ id: `text.payment-amount` as any })}
                    </span>
                    {checkpayment() < 0 ?
                    <strong className=' fs-5 fw-bold min-w-100px text-end text-danger'>({formatCurrent(Math.abs(checkpayment()))})</strong>
                    // <strong className=' fs-5 fw-bold min-w-100px text-end'>0</strong>
                    :                  
                    <strong className=' fs-5 fw-bold min-w-100px text-end'>{formatCurrent(Math.abs(checkpayment()))}</strong>
                    }
                  </div>
                </div>
              </div>
              
              
              </>
              
          }
          {
              tab == 'files' &&
              <div>
                <KTTable
                  pagination
                  columns={columns}
                  data={listFiles.filter((i:any)=>i.state != 'remove')}
                  title={<></>}
                  toolbar={<div className='custom-btn-add-file'>
                    {
                      !checkIsDisabledForm() &&
                      <KTButton action="new" onClick={handleAddModalFile} titleBtn={intl.formatMessage({ id: `text.attachments.add` as any })}/>
                    }
                  </div>}
                />
              </div>
            }
            
          </div>
          
        </div>
        <div className="row mt-6">
          <div className="col-12 text-end">
            {/* <Link to={'/payment-request'} className='btn btn-sm btn-secondary me-3' >Trở về</Link> */}
            {!checkIsDisabledForm() &&
              <FormSubmit
              type='modify'
              isSubmitting={isSubmitting}
              permission={permission}
              refSaveBtn={refSaveBtn}
              handleClose={() => navigate(-1)} />
            }
          </div>
        </div>
      </div>
    </form>
    
    <Modal size="lg" id='kt_modal_files' title={intl.formatMessage({ id: `text.attachments` as any })} show={isShowFiles} onHide={handleCloseModalFile} centered >
      <Modal.Header className='pb-3 '>
          <Modal.Title className="d-flex align-items-center">
            <h3> {intl.formatMessage({ id: `text.attachments` as any })}</h3>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-3 rounded-bottom">
          <div className="position-relative">
          <form onSubmit={handleSubmitFile(onSubmitFile)}>
              <div className="row">
                  <div className="col-12 col-md-12">
                    {
                      requiredDocument?.map((item:any,index: number) => (
                      <DivRoot key={index}>
                        <KTFormItem isRequired={item.isrequired} title={index+1 +'. '+ item.description} className='align-items-baseline mb-5' labelWidth='200' isHeight>
                          <Controller
                            control={controlFile}
                            name={item.id}
                            render={({ field: { value, onChange } }) => <InputFiles
                                maxSizes={item.maxfilesize}
                                maxFiles={item.numberoffile}
                                values={returnFileValues(item.id)}
                                isErrors={errorsFile[item.id]}
                                onChange={(data,value) => {
                                  let tempDelete = data.filter((i:any)=>i.state == 'remove').map((i:any)=>i.pathtofile)
                                  setFilesDelete(unique_arr(filesDelete.concat(tempDelete)))
                                  
                                  let temp = data.filter((i:any)=>i.state != 'remove')
                                  setValueFile(item.id,temp)
                                  onChange(temp)
                                  // setFiles(data)
                                  // console.log('data',data)
                                  // console.log('value',value)
                                  // handleOnChange(data)
                                }}
                              

                            />}
                          />
                        </KTFormItem>
                      </DivRoot>
                      ))
                    }
                      
                  </div>
              </div>
              
              <div className='d-flex mt-8 gap-3 justify-content-end'>
              <span className='btn btn-secondary' data-bs-dismiss='modal' aria-label='Close' onClick={e =>setIsShowFile(false)}>{intl.formatMessage({ id: `text.cancel` as any })}</span>
              <button className='btn btn-primary'>{intl.formatMessage({ id: `text.save` as any })}</button>
              </div>
          </form>
          </div>
      </Modal.Body>
  </Modal>
    {historiesApproval(approvalhistories, intl)}
    <ImportModalRequest
      {...{
          show: isShowImport,
          title: intl.formatMessage({ id: `text.import.title` as any }),
          filename: 'payment_import_sample.xlsx',
          filelink: `/samples/xlsx/request_payment_import_details_(${selectedLang}).xlsx`,
          // funcDowloadFile: downloadFileExample,
          handleClose() {
              setIsShowImport(false)
          },
          handleImport(data) {
            const file = data.files?.item(0)
            const arrPayments = optionsPayments('code')?.filter((i: any)=> i.value).map((i: any)=> i.value.toLocaleUpperCase()) as any
            if (file) {
                const formData = new FormData();
                formData.append("file", file);
                ApiService.post(`utilities/convertxlsxtoarray`, formData)
                    .then(({data}) => {
                      let a = JSON.parse(JSON.stringify(header)) as any
                      let tempTable = [] as any
                      let errRow = [] as any
                      data.forEach((item: any, index: number) => {
                      let tempData = [] as any
                        if(!arrPayments.includes(item[a[1]?.title]?item[a[1].title].toString().toLocaleUpperCase():item[a[1].title]) || !item[a[4]?.title] || !item[a[5]?.title] || !item[a[6]?.title] || !item[a[7]?.title]) {
                          errRow.push({
                            item: item,
                            index: index+1
                          })
                          return
                        }
                        for(let i = 0;i<a.length;i++) {
                          let temp  = {
                            title: a[i].title,
                            value: item[a[i].title]
                          } as any
                     
                          tempData.push(temp)
                        }
                        let ind = index - errRow.length
                        // console.log('tempData',tempData)
                        // setValue('storeid_'+ind,tempData[4].value)
                        // setValue('expensevaluecode_'+ind,tempData[1].value?tempData[1].value.toLocaleUpperCase():tempData[1].value)
                        let expensevaluecode = tempData[1].value?tempData[1].value.toLocaleUpperCase():tempData[1].value
                        let recordExpenses = optionsPayments('code').find((i: any)=> i.value == expensevaluecode)
                        // console.log('recordExpenses',recordExpenses)
                        let roundNum = checkCurrencyVND()?0:2
                        let total = Number((Math.round(+tempData[7].value*tempData[6].value*1000/1000)).toFixed(roundNum)) //tempData[7].value*tempData[6].value
                        let vatamount = Number((total*(tempData[8].value||0)).toFixed(roundNum))
                        let recordTable = [
                          { value: recordExpenses?.value, className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('code').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-code' },
                          { value: recordExpenses?.description, className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('name').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-name' },
                          { value: tempData[3].value, className: 'text-start' },
                          { value: tempData[4].value, className: '',  },
                          { value: tempData[5].value, className: '', },
                          {
                            value: tempData[6].value,
                            className: 'text-center',
                          },
                          {
                            value: formatCurrent(tempData[7].value),
                            className: 'text-center',
                          },
                          {
                            value: tempData[8].value||0,
                            className: 'text-center',
                          },
                          {
                            value: formatCurrent(total+vatamount/100),
                            readOnly: true,
                            className: 'text-center',
                          },
                          {
                            value: tempData[10].value,
                            className: 'text-center',
                          },
                        ] as any
                        // console.log('recordTable',recordTable)
                        tempTable.push(recordTable)
                      })
                      let defaultRow = [
                        [ 
                          { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('code').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-code' },
                          { value: '', className: '',dataEditor: SelectEditorPopup, optionsData: optionsPayments('name').filter((i:any)=>!checkChoise(i.value)), typeCell: 'custom-name' },
                          { value: '', className: 'text-start' },
                          { value: '', className: '',  },
                          { value: '', className: '',  },
                          { value: '', className: 'text-center' },
                          { value: '', className: 'text-center' },
                          { value: '', className: 'text-center' }, 
                          { value: '', className: 'text-center', readOnly: true },
                          { value: '', className: 'text-center' }, 
                        ],
                        
                      ]
                      if(errRow.length !=0){
                        let th = ''
                        let style = 'style="width: 100px; text-align: left;border: 1px solid #cacaca;padding: 3px"'
                        a.forEach((i: any, index: number) => {
                          if(index == 9 || index == 2) return
                          th += `<th ${style}>${i.title == '#'?intl.formatMessage({ id: `text.row` as any }):i.title}</th>` 
                        })

                        let tr = ''
                        let err = ``
                        let x = 0
                        let y = 0
                        errRow.forEach((item: any,index: number) => {
                          let td = `<td ${style}>${item.index}</td>`
                          for(let i = 1;i<a.length;i++) {
                            if(i != 9 && i != 2 ) {
                              
                              let value = item.item[a[i].title]
                              let stylerow = style
                              if( (!value &&(i == 1 || i == 4|| i==5|| i==6 || i ==7))) {
                                stylerow = 'style="width: 100px; text-align: left;border: 2px solid red;padding: 3px;"'
                                if(!x) {
                                  err += `<h4>${intl.formatMessage({ id: `text.payment.not-space` as any })}</h4>`
                                  x = 1
                                }
                                
                              }
                              if(i == 1 && value) {
                                stylerow = 'style="width: 100px; text-align: left;border: 2px solid red;padding: 3px;"'
                                if(!y) {
                                  err += `<h4>${intl.formatMessage({ id: `text.payment.not-map` as any })}</h4>`
                                  y = 1
                                }
                              }
                              td += `<td ${stylerow}>${value}</td>`
                            }
                            
                          }
                          tr += `<tr>${td}</tr>`
                        })
                        popupMessage({
                          width: 850,
                          icon: "info",
                          html: `${err}
                          <table style="margin: 8px auto">
                            <tr>
                              ${th}
                            </tr>
                            ${tr}
                          </table>`
                        })
                      }
                      if(tempTable.length != 0) {
                        tableData.forEach((i: any, index: number) => {
                          if(index < tempTable.length) {
                            return
                          }
                          setValue('storeid_'+index,'')
                          setValue('expensevaluecode_'+index,'')
                          setValue('remark_'+index,'')
                        })
                        setIsHasChange(true)
                        // tempTable = tempTable.map((item: any,index: number)=>({...item,index: index}))
                        console.log('tempTable',tempTable)
                        setTableData(tempTable.concat(defaultRow))
                        if(errRow.length ==0) {
                          popupMessage({ icon: 'success',description: intl.formatMessage({ id: `text.success-import` as any }), autoClose: true })
                        }
                      }
                    })
                    .catch(ApiShowError)
            }
          },
      }} />
    <SearchPopup {...{
            keys,
            filters,
            searchData,
            // className: 'popup-in-popup',
            setValue,
            selfSave: (data:any) => {
              // console.log('data',data)
              if(data.props) {
                return setValueGrid(data, tableData, setTableData)
              }
              for (const [key, value] of Object.entries(data)) {
                if(key != 'index') {
                  setValue(key,value)
                  if(key == 'sourceno') {
                    getInfoBank()
                    getAdvances(watch('sourceno'))
                    
                  }
                  if(key == 'employeeadvancerequestid') {
                    let tempData = employeeAdvance.filter((item: any) => (item.id != watch('employeeadvancerequestid') && item.open != false) || (item.id == dataQuery?.employeeadvancerequestid && dataQuery?.employeeadvancerequestid != watch('employeeadvancerequestid')))
                    setOptionsAdvances(formatOptionAdvance(tempData))
                  }
                  if(key == 'expenseid') {
                    if(watch('expenseid') && watch('status') && watch('status') == '284210000') {
                      renderDetails([],optionsUoms,optionsStoresName,true)
                    }
                  }
                }
              }
              if(data.index) {
                // console.log('data',data)
                // autoFillData(data.id,data.index)
                saveDimesion(data)
              }
            },
            handleClose: function () { 
              setKeys([]); setFilters([])
             }
        }} />
  </>
}

export default TemplateComponent


