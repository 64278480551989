import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { ApiService, ApiShowError, encryptData, formatOptions, getCookie, setCookie } from '../../../theme/helpers'
import { useAuth } from './core/Auth'
import { getPermissions } from './components/Login'
import { ExtendClearValue, InputSelect, KTFormItem, yup } from '../../../theme/partials'
import { Controller, useForm } from 'react-hook-form'
import { apiPrivate } from '../api'
import { yupResolver } from '@hookform/resolvers/yup'
import SearchPopup from '../../../theme/partials/layout/search-popup'
import { apiAd } from '../api/admin'

import axios from "axios"
const ApiCustom = axios.create()
ApiCustom.defaults.baseURL = process.env.REACT_APP_API_URL;
ApiCustom.defaults.timeout = 120 * 1000

const AuthAzure = () => {
  const { saveAuth, auth } = useAuth()
  const navigate = useNavigate();
  const [responsErr,setResponsErr] = useState<any>(null)
  const [isLoading,setIsLoading] = useState<any>(true)
  let code = window.location.search
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [userInfo,setUserInfo] = useState<any>(null)
  const setTokenForApi = (token: any) => {
    ApiCustom.interceptors.request.use(async (config: any) => {
      config.headers.Authorization = `bearer ${token}`
      config.headers['Access-Control-Max-Age'] = 86400
      return config;
    }, (error) => Promise.reject(error));
    
    ApiCustom.interceptors.response.use(
      response => response,
      async (error) => {
        return Promise.reject(error);
      }
  );
  }
  useEffect(() => {
    const token = getCookie('token')
    console.log('auth',auth)
    console.log('token',token)
    if (!auth || !token) {
      let env = "";
      if (process.env.REACT_APP_LOCAL != 'false') {
        env = "&env=local";
      }

      ApiService
        .get(`auth2/redirect${code}${env}`)
        .then(async({ data }) => {
          // console.log('data',data)
          data.user.rolecode = data.user.rolecodes.map((m: string) => encryptData(m))
          setUserInfo(data)
          saveAuth({ user: data.user, permissions: getPermissions(data.permissions) })
          if(data.accessToken) {
            setCookie('token',data.accessToken, 365)
          }
          await setTokenForApi(data.accessToken)
          getapprovalusersetups(data.user?.id,data)
          setTimeout(()=> {
            getDepartments()
            getStores()
            getUsersData(data.user?.id)
          },100)
        })
        .catch(({ response }) => {
          console.log("err", response.data);
          setResponsErr(response.data)
          setIsLoading(false)
        });
    }
    else {
      // navigate('/dashboard')
      window.location.href = '/dashboard'
    }

    
  }, [])
  const schema = yup.object().shape({
    // vendorid: yup.string().required(),
});
  const defaultValues: any = {
   
    submittoid: null,
    submittocode: null,
    submittoname: null,
    
    storeid: null,
    storecode: null,
    storename: null,
 
    vendorid: null,
    vendorcode: null,
    vendorname: null,
    departmentcode: null,
    departmentid: null,
    departmentname: null,
}
  const { register, reset, watch, control, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
})
  const[optionsUser,setOptionsUser] = useState<any>([])
  const[optionsStores,setOptionsStores] = useState<any>([])
  const[optionsDepartments,setOptionsDepartments] = useState<any>([])

  const [optionVendors,setoptionVendors] = useState<any>([])
  // const [allVendors,setAllVendors] = useState<any>([])
  const [keys, setKeys] = useState<any[]>([])
  const [typeSearch, setTypeSearch] = useState<string>('userid')
  const getVendors = async(arrDenied: any) => {
    let {data} =  await ApiCustom.get(`settings/vendors?vendortype=employee`)
    const options = data ? data.filter((i: any) => !arrDenied.includes(i.id)).map((i: any)=>({...i, label: i.number+'-'+i.vendorname, value: i.id})) : []
    setoptionVendors(options)
    return data
  }
  const [userSetup,setUserSetup] = useState<any>(null)
  const [arrUserSetup,setArrUserSetup] = useState<any>([])
  const getapprovalusersetups = async(id: any, userInfo?: any) => {
    let {data} =  await ApiCustom.get(`settings/approvalusersetups`)
    let dataUserSetup = data?.find((i: any) => i.userid == id)
    // console.log('data',data)
    setArrUserSetup(data.map((i: any)=>i.vendorid))
    setUserSetup(dataUserSetup)
    setIsLoading(false)
    let allVendors = await getVendors(data.map((i: any)=>i.vendorid))

    if(dataUserSetup) {
      reset(dataUserSetup)
      if(!dataUserSetup.isshowdataentryform) {
        saveAuthLocal(dataUserSetup, userInfo,allVendors)
      }
    }
  }
  const getStores = async() => {
    let {data} =  await ApiCustom.get('/settings/stores')
    const options = data ? data.map((i: any)=>({...i, label: i.code+'-'+i.storename, value: i.id})) : []
    setOptionsStores(options)
  }
  const getUsersData = async(userid: any) => {
    let {data} =  await ApiCustom.get('/settings/users')
    const options = data ? data.filter((i: any)=>i.id != userid).map((item: any) => ({ ...item, title: item.fullname, description: `${item.username}-${item.authenticationemail}` })) : []
    setOptionsUser(options)
  }
  const getDepartments = async() => {
    let {data} =  await ApiCustom.get('/settings/departments')
    const options = data ? data.map((i: any)=>({...i, label: i.departmentname, value: i.id})) : []
    setOptionsDepartments(options)
  }
  const saveAuthLocal = (data: any, info?: any, allVendors?: any) => {
    // console.log('data',data)
    // console.log('info',info)
    let dataDept = null as any
    if(allVendors) {
      dataDept = allVendors?.find((i: any) => i.id == data.vendorid)
    }
    // console.log('dataDept',dataDept)
    // console.log('allVendors',allVendors)
    
    let tempUser = JSON.parse(JSON.stringify(userInfo||info))
        tempUser.user.vendorcode = data.vendorcode
        tempUser.user.vendorid = data.vendorid
        tempUser.user.vendorname = data.vendorname

        if(dataDept && dataDept.departmentid) {
          tempUser.user.departmentid = dataDept.departmentid
          tempUser.user.departmentname = dataDept.departmentname
          tempUser.user.departmentcode = dataDept.departmentcode
        }
        else if(data.departmentid) {
          tempUser.user.departmentid = data.departmentid
          tempUser.user.departmentname = data.departmentname
          tempUser.user.departmentcode = data.departmentcode
        }
        tempUser.user.storeid = data.storeid
        tempUser.user.storename = data.storename
        tempUser.user.storecode = data.storecode
        
        saveAuth({ user: tempUser.user, permissions: getPermissions(tempUser.permissions) })
        // navigate('/dashboard')
        // if(info.accessToken) {
        //   setCookie('token',info.accessToken, 365)
        // }
        // navigate('/dashboard')
        window.location.href = '/dashboard'
  }
  const skipFunc = async() => {
    let apiurl = 'settings/approvalusersetups'
    let isEdit = false
    if(userSetup) {
      isEdit = true
      apiurl = apiurl+'/'+userSetup.id+'?islogin1st=true'
    }
    const method = isEdit?'put':'post'
    let tempData = {
      userid: userInfo?.user?.id,
      fullname: userInfo?.user?.fullname,
      username: userInfo?.user?.username,
      email: userInfo?.user?.contactemail,
      
      isshowdataentryform: false,

    }
    await ApiCustom[method](apiurl, tempData)
    window.location.href = '/dashboard'

  }
  return <>
  {!isLoading && !responsErr && (!userSetup ||(userSetup && (userSetup.isshowdataentryform) )) ? <>
    <form id='kt_info_form' className='form w-100 p-8 shadow-sm ' onSubmit={handleSubmit(async (data: any) => {
    // console.log('data',data)
    try {
        let apiurl = 'settings/approvalusersetups'
        let isEdit = false
        if(userSetup) {
          isEdit = true
          apiurl = apiurl+'/'+userSetup.id+'?islogin1st=true'
        }
        const method = isEdit?'put':'post'
        let tempData = {
          userid: userInfo?.user?.id,
          fullname: userInfo?.user?.fullname,
          username: userInfo?.user?.username,
          email: userInfo?.user?.contactemail,
          
          vendorcode: data.vendorcode,
          vendorid: data.vendorid,
          vendorname: data.vendorname,

          departmentid: data.departmentid,
          departmentname: data.departmentname,
          departmentcode: data.departmentcode,
          
          storeid: data.storeid,
          storename: data.storename,
          storecode: data.storecode,

          submittocode: data.submittocode,
          submittoid: data.submittoid,
          submittoname: data.submittoname,
          isshowdataentryform: false,

        }
        await ApiCustom[method](apiurl, tempData)
        // let vendorId = data.vendorid as any
        // let apiVendor =  `settings/vendors/${vendorId}?islogin1st=true`
        // let tempDept = {
        //   departmentid: data.departmentid,
        //   departmentname: data.departmentname,
        //   departmentcode: data.departmentcode,
        // }
        // await ApiCustom.put(apiVendor, tempDept)

        saveAuthLocal(data)
        // popupMessage({ icon: 'success', autoClose: true })
        // if(auth?.user?.id == data.userid) return logout(true)
        // navigate('/system-settings/user-setups')
    } catch (error) { ApiShowError(error) }
})}>
  <div className='text-center mb-10'>
    {/* <div className='m-auto mb-2'>
      <img src="/media/images/logo_sp_trungnguyenlegend.png" alt="loginimage" />
    </div> */}
    <div className=' fw-bold fs-2 mb-2'>
      {intl.formatMessage({ id: "login.update-info" as any })}
    </div>
    {/* <div className=''>
      <i className='text-dark text-gray-700'>
        <span className='text-danger'>* </span>{intl.formatMessage({ id: "login.required" as any })}
      </i>
    </div> */}
  </div>

  <div className='fv-row mb-5'>
    <KTFormItem title='text.vendor.employee'>
        <Controller
            name="vendorid"
            control={control}
            render={({ field: { value, onChange } }) => <InputSelect
                isClearable
                options={optionVendors}
                value={optionVendors.find((f: any) => f.value == value) || null}
                className={`form-control form-control-sm w-100 select-custom ${errors.vendorid && 'form-error'}`}
                onChange={(e) => {
                    setValue('vendorcode', null)
                    setValue('vendorname', null)
                    if (!e) return onChange(null)
                    onChange(e.value)
                    setValue('vendorcode', e.number)
                    setValue('vendorname', e.vendorname)
                    setValue('departmentcode', e.departmentcode)
                    setValue('departmentid', e.departmentid)
                    setValue('departmentname', e.departmentname)
                }} />}
        />
    </KTFormItem>
    <KTFormItem title='text.department-name'>
      <Controller
        name="departmentid"
        control={control}
        render={({ field: { value, onChange } }) => <InputSelect
            options={optionsDepartments}
            value={optionsDepartments.find((f: any) => f.value == value) || null}
            className={`form-control form-control-sm w-100 select-custom ${errors.departmentid && 'form-error'}`}
            onChange={(e) => {
                if (!e) return onChange(null)
                onChange(e.value)
                let record = optionsDepartments.find((i: any)=> i.value == e.value)
                if(record) {
                  setValue('departmentcode', record.code)
                  setValue('departmentname', record.departmentname)
                }
            }} />}
        />
      {/* <input readOnly={true} className={clsx('form-control form-control-sm form-control-solid')} {...register('departmentname')} /> */}
    </KTFormItem>
    {/* <KTFormItem title="text.store">
      <Controller
          name="storeid"
          control={control}
          render={({ field: { value, onChange } }) => <InputSelect
              isClearable
              options={optionsStores}
              value={optionsStores.find((f: any) => f.value == value) || null}
              onChange={(e) => {
                  setValue('storecode', null)
                  setValue('storename', null)
                  if (!e) return onChange(null)
                  onChange(e.value)
                  setValue('storecode', e.code)
                  setValue('storename', e.storename)
              }} />}
      />
    </KTFormItem> */}
    <KTFormItem title='text.submit-to' isHeight>
      <ExtendClearValue
          hide={!watch("submittoid")}
          handleClear={() => {
              setValue("submittoid", null)
              setValue("submittocode", null)
              setValue("submittoname", null)
              setValue("submittoifamountexceed", null)
          }}>
          <input
              className={`form-control form-control-sm ${errors.submittoid && 'form-error'}`} {...register('submittoname')}
              readOnly
              onClick={() => {
                  setTypeSearch('submittoid')
                  setKeys([
                      ['submittoid', 'id'],
                      ['submittocode', 'username'],
                      ['submittoname', 'fullname']
                  ])
              }} />
      </ExtendClearValue>
  </KTFormItem>
  </div>
  
  
  <div className='d-flex gap-4 flex-stack mt-12 mb-6'>
    <span onClick={skipFunc} className='min-w-150px rounded-5 border-black border border-2 border-solid btn btn-sm btn-secondary'>{intl.formatMessage({ id: "text.skip" })}</span>
    <button
      type='submit'
      id='kt_sign_in_submit'
      className='min-w-150px btn-sm btn bg-light-orange rounded-5 border-orange border border-2 border-solid text-white fw-bolder'
    >
      {!loading && <span className='indicator-label'>{intl.formatMessage({ id: "login.confirm" as any })}</span>}
      {loading && <span className='indicator-progress' style={{ display: 'block' }}>
        ...
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
      }
    </button>
  </div>
  
 
</form>
<SearchPopup
    {...{
        // isLoading:  ,
        keys,
        setValue,
        searchData: optionsUser,
        handleClose: function () { setKeys([]) }
    }} />
  </>:<>
  {responsErr ?<>
    <div className='form w-100 p-8 shadow-sm'>
      <div className='text-center text-danger fw-bold fs-1 mb-8'>
        <span className=''>Error: {responsErr.errorCode}</span>
        {/* <span>{responsErr.name}</span> */}
      </div>
      <div>{responsErr.errorMessage}</div>
      <div className='text-center mt-5'>
        <Link className='btn btn-sm rounded-5 border-orange border border-2 border-solid btn btn-sm btn-light-orange col-12 col-lg-3 text-white' to={'/login'}>{intl.formatMessage({ id: "text.login" })}</Link>
      </div>
    </div>
    </> :<div className='custom-loading loading-abs'><span className='loader'></span></div>
  
  }
  </>
  }
  
  </>
}
export { AuthAzure }
