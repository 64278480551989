/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../theme/assets/ts/_utils'
import { MasterLayout } from '../../theme/layout/MasterLayout'
import { App } from '../App'
// auth
import { ApiService, ApiShowError, setCookie, WithChildren } from '../../theme/helpers'
import { AuthModel, AuthPage, getAuth, removeAuth, setAuth, useAuth } from '../modules/auth'
import { AuthLayout } from '../modules/auth/AuthLayout'
import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
import { Login } from '../modules/auth/components/Login'
import { getIsPermission, getIsRole } from '../modules/auth/Permission'
// settings
import { AccountProfilePage } from '../modules/account/profile'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { settingMenusData, SystemSettings } from '../modules/settings'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Dashboard } from '../pages/dashboard/Dashboard'
import { dataPages } from './routerData'
// page
import { UpdatePage } from '../../theme/partials'
import AdvanceRequest from '../pages/advanceRequest'
import AdvanceRequestDetails from '../pages/advanceRequest/details'
import { SettingCustomers, SettingCustomersForm, SettingDepartments, SettingDepartmentsForm, SettingExpenses, SettingExpensesForm, SettingItems, SettingItemsForm, SettingStores, SettingStoresForm, SettingVendors, SettingVendorsForm, SMerchants, SMerchantsForm } from '../pages/catalogue'
import { ConfigMenuStructureTier, ConfigStoreTier, ConfigStoreTierForm, ConfigTimeBasedMenuTier, SelfServiceSetupForm } from '../pages/configuration'
import AdAttachmentsPage, { AdAttachmentsDetailPage } from '../pages/configuration/attachments'
import AdEmailTemplate, { AdEmailTemplatesDetailPage } from '../pages/configuration/emailTemplate'
import AdNoSeriesPage, { AdNoSeriesDetailPage } from '../pages/configuration/noSeries'
import { FNBCategories, FNBCategoriesForm, FNBCustomizations, FNBCustomizationsForm, FNBHospitalityItems, FNBHospitalityItemsForm, FNBMenuStructures, FNBMenuStructuresForm, FNBSubCategories, FNBSubCategoriesForm, FNBTags, FNBTagsForm, FNBTimeBasedMenus, FNBTimeBasedMenusForm } from '../pages/fnb'
import OrderRequest from '../pages/orderRequest'
import OrderActionFromMail from '../pages/orderRequest/actionsFromMail'
import OrderRequestDetails from '../pages/orderRequest/details'
import PaymentRequest from '../pages/paymentRequest'
import PaymentRequestDetails from '../pages/paymentRequest/details'
import AdvancePrint from '../pages/print/advance'
import OrderPrint from '../pages/print/order'
import PaymentPrint from '../pages/print/payment'
import { AuthAzure } from '../modules/auth/AuthAzure'
import { useIntl } from 'react-intl'
import PDimensions from '../pages/catalogue/dimensions'
import PDimensionsForm from '../pages/catalogue/dimensions/detail'
import PromotionsPage from '../pages/discounts/promotions'
import PromotionsDetailPage from '../pages/discounts/promotionsDetail'
import CouponsDetailPage from '../pages/discounts/couponsDetail'
import CouponsPage from '../pages/discounts/coupons'
import TaxPostingSetup from '../pages/configuration/taxPostingSetup'
import StaffPermissionsGroup from '../pages/configuration/staffPermissionsGroup'
import StaffPermissionsGroupDetail from '../pages/configuration/staffPermissionsGroup/detail'
import CustomerPriceGroups from '../pages/configuration/customerPriceGroups'

interface IPath {
  path: string
  children?: JSX.Element
  type?: number | string[]
  isNotChangeElement?: boolean
}

export interface MergedProps {
  keyElement?: any
  pathElement?: any
  permission?: string
}

const SuspensedView: React.FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: { '0': baseColor, },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <React.Suspense fallback={<TopBarProgress />}>{children}</React.Suspense>
}
export async function isAuth() {
  try {
    const auth = getAuth()
    // console.log('auth',auth)
    let body = {system: `TNF_IRMS`}
    const newAuth = await ApiService.post(`/auth/authenticate/${auth?.user?.id}`,body)
    return newAuth?.data||{}
  } catch (error) {
    ApiShowError(error)
  }
  return undefined
}
async function requestAuthenticated(id: string) {
  try {
    const newAuth = await ApiService.post(`/auth/authenticate/${id}`)
    return newAuth.data
  } catch (error) {
    ApiShowError(error)
  }
}
export async function validateToken() {
  let check = false
  const auth = getAuth() as any
  const saveAuth = (auth: AuthModel) => {
    setAuth(auth)
    if (auth) return setAuth(auth)
    removeAuth()
  }
  // console.log('auth',auth)
  if (auth && auth.user?.id) {
    const { isAuthenticated, newAccessToken } = await isAuth()||{}
    // console.log('isAuthenticated',isAuthenticated)
    // console.log('newAccessToken',newAccessToken)
    if (isAuthenticated && newAccessToken) {
      await setCookie('token',newAccessToken,365)
    }
    if (isAuthenticated) {
      check = true
    }
  }
  // console.log('check',check)
  if(check) return null
  if (!check) {
    saveAuth(undefined as any)
    return redirect("/login");
  }
  // console.log('check',check)
  return null
} 
const AppRoutes = () => {
  const intl = useIntl()
  const { saveAuth, auth } = useAuth()
  // console.log('auth',auth)
  // async function loadInitAuth() {
  //   let haveAuth = false
  //   if (auth && auth.user?.id) {
  //     const { isAuthenticated, newAccessToken } = await requestAuthenticated(auth.user?.id)
  //     if (newAccessToken && isAuthenticated) saveAuth({ ...auth, token: newAccessToken })
  //     if (isAuthenticated) haveAuth = true
  //   }
  //   if (!haveAuth)
  //     throw new Response("Sorry, your request could not be processed.", { status: 401, statusText: 'Unauthorized' });
  //   return null
  // }

  function sendError(code: 401 | 403) {
    throw new Response('', { status: code, statusText: code == 401 ? 'Unauthorized' : 'Forbidden' });
  }
  
  function initPath({ path, type, children, isNotChangeElement }: IPath) {
    let dataElement: any = {}
    let isPermission = false
    if (!Array.isArray(type) && typeof type == 'number' && type.toString().length == 6) {
      dataElement.permission = type.toString()
      isPermission = getIsPermission([type.toString(), 'READ'])
    } else if (Array.isArray(type) && typeof type[0] == 'string') {
      isPermission = getIsRole(type)
    } else {
      const dataPage = dataPages.find(f => {
        return f.pathElement == (path.split('/:id')[0])
      })
      if (dataPage) {
        dataElement = dataPage
        isPermission = getIsPermission([dataPage.permission, 'READ'])
      }
    }

    let options: any = {
      path,
      // loader: validateToken()
    }

    if (children) options.element = isNotChangeElement
      ? <SuspensedView children={children} />
      : <SuspensedView children={React.cloneElement(children, { ...dataElement })} />
    return options
  }

  return <RouterProvider router={createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorsPage />,
      children: [
        { path: "/", element: auth ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace /> },
        {
          path: "/",
          element: <MasterLayout />,
          children: [
            // { ...initPath({ path: "/dashboard", children: <DashboardWrapper /> }) },
            { ...initPath({ path: "/dashboard", children: <Dashboard /> }) },
            { ...initPath({ path: "/account/profile", children: <AccountProfilePage />, type: 200001 }) },
            ///ROUTE:REQUESTS
            { ...initPath({ path: "/requests/order", children: <OrderRequest permission='500002' />, isNotChangeElement: true }) },
            { ...initPath({ path: "/requests/order/:id", children: <OrderRequestDetails permission='500002' />, isNotChangeElement: true }) },
            { ...initPath({ path: "/requests/advance", children: <AdvanceRequest permission='500003' />, isNotChangeElement: true }) },
            { ...initPath({ path: "/requests/advance/:id", children: <AdvanceRequestDetails permission='500003' />, isNotChangeElement: true }) },
            { ...initPath({ path: "/requests/payment", children: <PaymentRequest permission='500004' />, isNotChangeElement: true }) },
            { ...initPath({ path: "/requests/payment/:id", children: <PaymentRequestDetails permission='500004' />, isNotChangeElement: true }) },
            ///ROUTE:CATELOGUE
            { ...initPath({ path: "/catalogue/item-list", children: <SettingItems /> }) },
            { ...initPath({ path: "/catalogue/item-list/:id", children: <SettingItemsForm /> }) },
            { ...initPath({ path: "/catalogue/customer-list", children: <SettingCustomers /> }) },
            { ...initPath({ path: "/catalogue/customer-list/:id", children: <SettingCustomersForm /> }) },
            { ...initPath({ path: "/catalogue/vendor-list", children: <SettingVendors /> }) },
            { ...initPath({ path: "/catalogue/vendor-list/:id", children: <SettingVendorsForm /> }) },
            { ...initPath({ path: "/catalogue/expenses", children: <SettingExpenses /> }) },
            { ...initPath({ path: "/catalogue/expenses/:id", children: <SettingExpensesForm /> }) },
            { ...initPath({ path: "/catalogue/stores", children: <SettingStores /> }) },
            { ...initPath({ path: "/catalogue/stores/:id", children: <SettingStoresForm /> }) },
            { ...initPath({ path: "/catalogue/departments", children: <SettingDepartments /> }) },
            { ...initPath({ path: "/catalogue/departments/:id", children: <SettingDepartmentsForm /> }) },
            { ...initPath({ path: "/catalogue/dimensions", children: <PDimensions /> }) },
            { ...initPath({ path: "/catalogue/dimensions/:id", children: <PDimensionsForm /> }) },
            ///ROUTE:FNB
            { ...initPath({ path: "/fnb/categories", children: <FNBCategories /> }) },
            { ...initPath({ path: "/fnb/categories/:id", children: <FNBCategoriesForm /> }) },
            { ...initPath({ path: "/fnb/sub-categories", children: <FNBSubCategories /> }) },
            { ...initPath({ path: "/fnb/sub-categories/:id", children: <FNBSubCategoriesForm /> }) },
            { ...initPath({ path: "/fnb/menu-structures", children: <FNBMenuStructures permission='600003' />, isNotChangeElement: true }), },
            { ...initPath({ path: "/fnb/menu-structures/:id", children: <FNBMenuStructuresForm permission='600003' />, isNotChangeElement: true }), },
            { ...initPath({ path: "/fnb/hospitality-items", children: <FNBHospitalityItems /> }) },
            { ...initPath({ path: "/fnb/hospitality-items/:id", children: <FNBHospitalityItemsForm /> }) },
            { ...initPath({ path: "/fnb/tags", children: <FNBTags /> }) },
            { ...initPath({ path: "/fnb/tags/:id", children: <FNBTagsForm /> }) },
            { ...initPath({ path: "/fnb/customizations", children: <FNBCustomizations /> }) },
            { ...initPath({ path: "/fnb/customizations/:id", children: <FNBCustomizationsForm /> }) },
            { ...initPath({ path: "/fnb/time-based-menus", children: <FNBTimeBasedMenus /> }) },
            { ...initPath({ path: "/fnb/time-based-menus/:id", children: <FNBTimeBasedMenusForm /> }) },
            { ...initPath({ path: "/fnb/merchant-list", children: <SMerchants /> }) },
            { ...initPath({ path: "/fnb/merchant-list/:id", children: <SMerchantsForm /> }) },
            { ...initPath({ path: "/discounts/promotions", children: <PromotionsPage /> }) },
            { ...initPath({ path: "/discounts/promotions/:id", children: <PromotionsDetailPage /> }) },
            { ...initPath({ path: "/discounts/coupons", children: <CouponsPage /> }) },
            { ...initPath({ path: "/discounts/coupons/:id", children: <CouponsDetailPage /> }) },
            ///ROUTE:CONFIGURATION
            { ...initPath({ path: "/configuration/email-templates", children: <AdEmailTemplate /> }) },
            { ...initPath({ path: "/configuration/email-templates/:id", children: <AdEmailTemplatesDetailPage /> }) },
            { ...initPath({ path: "/configuration/attachments", children: <AdAttachmentsPage /> }) },
            { ...initPath({ path: "/configuration/attachments/:id", children: <AdAttachmentsDetailPage /> }) },
            { ...initPath({ path: "/configuration/no-series", children: <AdNoSeriesPage /> }) },
            { ...initPath({ path: "/configuration/no-series/:id", children: <AdNoSeriesDetailPage /> }) },
            { ...initPath({ path: "/configuration/store-tier", children: <ConfigStoreTier /> }) },
            { ...initPath({ path: "/configuration/store-tier/:id", children: <ConfigStoreTierForm /> }) },
            { ...initPath({ path: "/configuration/menu-structure-tier", children: <ConfigMenuStructureTier /> }) },
            { ...initPath({ path: "/configuration/time-based-menu-tier", children: <ConfigTimeBasedMenuTier /> }) },
            { ...initPath({ path: "/configuration/payment-tier", children: <UpdatePage /> }) },
            { ...initPath({ path: "/configuration/self-service-setup", children: <SelfServiceSetupForm /> }) },
            { ...initPath({ path: "/configuration/staffs-permission-group", children: <StaffPermissionsGroup /> }) },
            { ...initPath({ path: "/configuration/staffs-permission-group/:id", children: <StaffPermissionsGroupDetail /> }) },
            { ...initPath({ path: "/configuration/tax-posting-setup", children: <TaxPostingSetup /> }) },
            { ...initPath({ path: "/configuration/customer-price-groups", children: <CustomerPriceGroups /> }) },
            /*  {
               ...initPath({ path: "/configuration/payment-tier/:id", children: <ConfigPaymentTierForm /> })
             }, */
            ///ROUTE:SETTINGS
            {
              ...initPath({ path: 'system-settings', type: ['SUPER', 'ADMIN'] }),
              children: [{ path: '', element: <SuspensedView children={<SystemSettings />} />, },
              ...settingMenusData.reduce((tol: any[], pre) => {
                let pages = pre.component
                  ? [initPath({ path: pre.key, children: pre.component, type: pre.role ? [pre.role] : undefined })]
                  : []
                if (pre.child)
                  pages.push(initPath({ path: `${pre.key}/:id` as any, children: pre.child, type: pre.role ? [pre.role] : undefined }))
                return [...tol, ...pages]
              }, [])]
            },
          ]
        },
        {
          path: "/",
          element: <AuthLayout />,
          children: [
            { path: 'login', element: <Login />, },
            {
              path: "/redirect/*",
              element: <AuthAzure />,
            },
            { path: "forgot-password", element: <ForgotPassword />, },
          ]
        },
        { path: "/print-order/:id", element: <OrderPrint />, },
        { path: "/print-advance/:id", element: <AdvancePrint />, },
        { path: "/print-payment/:id", element: <PaymentPrint />, },
        { path: "/clients/:id/:token", element: <OrderActionFromMail />, }
      ],
    },
  ])} />
}

export { AppRoutes }

